import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop-sheet',
  templateUrl: './image-crop-sheet.component.html',
  styleUrls: ['./image-crop-sheet.component.scss']
})
export class ImageCropSheetComponent {

  isRound: boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data : ImageCropSheetData,
    public _bottomSheetRef: MatBottomSheetRef<ImageCropSheetComponent>
  ) {
    if (data.isRound)
      this.isRound = data.isRound
  }

  imageCropped(event: ImageCroppedEvent) {
    this.data.imageCropped(event)
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  cancelCropping() {
    this.data.cancelled ? this.data.cancelled() : null;
    this._bottomSheetRef.dismiss()
  }
}

export interface ImageCropSheetData {
  aspectRatio: number,
  image: File,
  isRound?: boolean,
  imageCropped(event: ImageCroppedEvent): void,
  cancelled?(): void
}
