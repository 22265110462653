import { NgModule } from "@angular/core";

import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS}  from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from "@angular/material/bottom-sheet";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";



@NgModule({
  imports: [
	],
  exports: [
		MatToolbarModule,
		MatFormFieldModule,
		MatIconModule,
		MatButtonModule,
		MatInputModule,
		MatSelectModule,
		MatSnackBarModule,
		MatDividerModule,
		MatListModule,
		MatBottomSheetModule,
		MatProgressBarModule,
		MatDialogModule,

		ReactiveFormsModule
	],
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline'
			}
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
			useValue: {
				duration: 3000
			}
		},
		{
			provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, 
			useValue: {
				hasBackdrop: true,
				maxWidth: 532,
				panelClass: 'app-sheet-maxwidth',
				backdropClass: 'app-darker-backdrop',
				closeOnNavigation: true
			}
		}

	]
})
export class MatCommonImportsModule { }