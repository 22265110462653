import { resolve } from '@angular-devkit/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { from, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToolbarScaffoldService } from 'src/app/components/toolbar-scaffold/toolbar-scaffold.service';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes.guard';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatabaseService } from 'src/app/services/database.service';
import { FormService } from 'src/app/services/form.service';
import { NotificationService } from 'src/app/services/notification.service';
import { User, UserType, UserTypeNames } from '../../../types/user.type'

@Component({
  selector: 'app-register-finish',
  templateUrl: './register-finish.component.html',
  styleUrls: ['./register-finish.component.scss']
})
export class RegisterFinishComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  showPassword: boolean = false;
  user: User;

  constructor(
    public fs: FormService,
    private auth: AuthenticationService,
    private router: Router,
    private snackbar: MatSnackBar,
    private database: DatabaseService,
    private scaffold: ToolbarScaffoldService,
    private notificationService: NotificationService,
    private analytics: AngularFireAnalytics
  ) {
    this._unsubscribeAll = new Subject()
  }

  formIsDirty: boolean = false;
  isDone: boolean = false;

  canDeactivate(): boolean | Observable<boolean> {
    return !this.formIsDirty || this.isDone
  }

  private _unsubscribeAll: Subject<any>;

  ngOnInit(): void {

    this.database.getCurrentUserData().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(
      (res) => {
        this.user = res

        if (this.user.data?.finished_registration) {
          this.isDone = true
          this.notificationService.requestPermission()
          this.router.navigate(['/home'])
        }
      }
    )

    
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()

    if (!this.isDone) {
      this.analytics.logEvent("registration_cancel", {step: 3, page: "Finish Account"})
    }
  }

  get email() {
    return this.user?.multiFactor.user.email
  }
  get userType() {
    return UserTypeNames.get(this.user?.data?.user_type)
  }

}
