import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatabaseService } from 'src/app/services/database.service';
import { FormService } from 'src/app/services/form.service';
import { User } from 'src/app/types/user.type';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit {

  @Input('size') size: number = 100;
  @Input('src') src: string = null;
  @Input('type') type: 1 | 2 = undefined

  constructor(
    private matIconReg: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.size = 100;
    this.src = null

    this.matIconReg.addSvgIcon(
      `person_svg`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/person.svg")
    );
    this.matIconReg.addSvgIcon(
      `placeholder_sl`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/profile_placeholder_sl.svg")
    );
    this.matIconReg.addSvgIcon(
      `placeholder_pg`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/profile_placeholder_pg.svg")
    );
  }


  ngOnInit(): void {
  }

}
