import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ToolbarScaffoldService } from 'src/app/components/toolbar-scaffold/toolbar-scaffold.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  constructor(
    public auth: AngularFireAuth,
    private snackbar: MatSnackBar,
    private router: Router,
    private scaffold: ToolbarScaffoldService,
    private analytics: AngularFireAnalytics
  ) {
  }
  ngOnDestroy(): void {
    if (!this.verified) {
      this.analytics.logEvent("registration_cancel", {step: 2, page: "Verify email address"})
    }
  }


  currentUser: any;

  verified: boolean = false;

  ngOnInit(): void {
  }

  verifyAgain(afterReload: boolean = false) {
    this.scaffold.setLoading(true)

    this.auth.currentUser.then(user => {
      if (user.emailVerified) {
        this.verified = true

        this.router.navigate(['home'])
        this.snackbar.open("Your e-mail address has been verified succesfully!")
      } else 
      if (!afterReload) {
        user.reload().then((user) => {
          this.verifyAgain(true)
        })
      }
      else {
        this.scaffold.setLoading(false)
        this.snackbar.open("Your e-mail address has not yet been verified. Please try again")
      }
    })
  }

  resendEmail() {
    this.scaffold.setLoading(true)

    this.auth.currentUser.then((user) => {
      user.sendEmailVerification().then(() => {
        this.snackbar.open(`A new verification e-mail has been sent to ${user.email}.`)

        this.scaffold.setLoading(false)
      }, (err) => {
        this.snackbar.open(`Something went wrong. Please try again`)

        this.scaffold.setLoading(false)
      })
    })
  }

}
