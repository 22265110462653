export const environment = {
  production: false,
  useEmulators: false,

  firebase: {
    apiKey: "AIzaSyB1iSvSHdX83ho7UOu_VyZQAHYGDrGJuwo",
    authDomain: "capture-me-dev.firebaseapp.com",
    projectId: "capture-me-dev",
    storageBucket: "capture-me-dev.appspot.com",
    messagingSenderId: "44245279038",
    appId: "1:44245279038:web:3f01bb245733b205680c6f",
    measurementId: "G-1E4S1PDPDR"
  },

  APP_NAME: "Capture Me STAGING",

  version: {
    name: '1.2.1',
    suffix: '-staging'
  }
};
