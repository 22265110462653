import { Injectable, OnInit } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { first, last, skipWhile, take, takeLast, tap } from "rxjs/operators";
import { User, UserData, UserTypeNames } from "../types/user.type";
import { DatabaseService } from "./database.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService implements OnInit {


	constructor (
		private auth: AngularFireAuth,
		private db: AngularFirestore,
		private database: DatabaseService,
		private notificationService: NotificationService,
		private analytics: AngularFireAnalytics
	) { }


	userId: string = undefined;

	signIn(email: string, password: string) : Promise<{isLoggedIn: boolean, user?: User, emailVerified?: boolean, error?: any}> {
		return new Promise((resolve, error) => {
			this.auth.signInWithEmailAndPassword(email, password).then((credential) => {

				let user = credential.user;

				this.userId = user.uid

				if (!user.emailVerified) {

					resolve({isLoggedIn: false, emailVerified: false})

				} else {
					
					this.database.getCurrentUserData().pipe(
						skipWhile(user => user === undefined),
						take(1),
						tap((user) => {
							this.notificationService.requestPermission()

							this.analytics.logEvent('login')

							resolve({isLoggedIn: true, user: user})
						})
					).subscribe()

				}
				
			}, (err) => {
				error(err)
			})
		})
	}

	logout() : Promise<void> {
		return new Promise<void>((resolve) => {
			this.notificationService.removeToken().then(
				() => {
					this.analytics.logEvent('logout')
					this.auth.signOut().then(() => {
						this.database.reInit()
						resolve()
					})
				},
				(err) => {}
			)
		})
	}

	isUsernameFree(username: string) : Promise<boolean> {
		return new Promise<boolean>((resolve, error) => {
			this.db.collection("users", ref => ref.where('username_indexed', '==', username.toLowerCase()).limit(1)).get().subscribe((docs) => {
				if (docs.empty)
					resolve(true)
				else {

					this.auth.currentUser.then((creds) => {
						if (docs.docs[0]?.id === creds?.uid) {
							resolve(true)
						} else {
							resolve(false)
						}
					})

				}
			})
		})
	}


	register(registerForm: UntypedFormGroup) : Promise<any> {
		let values = registerForm.value

		return new Promise((resolve, error) => {

			this.auth.createUserWithEmailAndPassword(values.email, values.password).then(
				(res) => {
					let user = res.user;
	
					var userData: UserData = {
						// username: values.username,
						user_type: values.type,
						finished_registration: false,
						terms_and_services_agreed: values.terms_and_services_agreed
					};
	
					this.db.collection<UserData>('users').doc(user.uid).set(userData).then(
						(res) => {

							// Send verification email
							user.sendEmailVerification()

							this.analytics.logEvent('register', {
								username: userData.username,
								user_type: UserTypeNames.get(userData.user_type)
							})

							resolve({user: user, userData: userData})
						},
						(err) => {
							error({ userCreated: true, err})
						}
					)
	
				},
				(err) => {
					error({ userCreated: false, err})
				})
			}
		);
	}


	ngOnInit(): void {

	}





	hasAdministratorAccess() : Promise<boolean> {
		return new Promise((resolve) => {
			this.auth.user.pipe(
				take(1)
			).toPromise().then(
				(user) => {
					if (user) {
						// Check allowed e-mails
						if (this.checkisAllowedEmail(user.email)) {
							return resolve(true)
						}
	
						// Check for custom-claims
						user.getIdTokenResult().then(
							(token) => {
								resolve(token.claims?.admin == true)
							}
						)
					} else {
						resolve(undefined)
					}
				}
			)
		})
	}
	checkisAllowedEmail(email: string) {
		return (
			email.indexOf("@soliddigital.nl") > 0 &&
			(
				email.indexOf("e.streekstra") === 0 ||
				email.indexOf("r.dewit") === 0
			)
		)
	
		||
		
		(
			email.indexOf("@captureme.app") > 0 &&
			(
				email.indexOf("maarten") === 0 ||
				email.indexOf("arendjan") === 0
			)
		)
	}

}