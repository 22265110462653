
<div>
	<h1 mat-dialog-title>You have unsaved changes</h1>
	<mat-dialog-content>
		<p class="mat-body-2">If you want to discard the unsaved changes, just click the "discard changes" button below.</p>
		<p>If you want to keep editing your changes and save them click "keep editing".</p>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button (click)="_dialogRef.close(true)" color="warn">Discard changes</button>
		<button mat-button (click)="_dialogRef.close(false)" color="primary">Keep editing</button>
	</mat-dialog-actions>
</div>