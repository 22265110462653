import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { mergeMap, mergeMapTo, skipWhile, take, takeUntil } from 'rxjs/operators';
import { NotificationSnackBarComponent } from '../components/notification-snack-bar/notification-snack-bar.component';
import { DatabaseService } from './database.service';

import { DeviceUUID } from 'device-uuid';
import { User } from '../types/user.type';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {

  private device: string;

  constructor(
    private messaging: AngularFireMessaging,
    private auth: AngularFireAuth,
    private database: DatabaseService,
    private snackbar: MatSnackBar,
    private router: Router,
    private analytics: AngularFireAnalytics
  ) {

    this._unsubscribeAll = new Subject<any>()

    // console.log("NOTIFICATIONSERVICE CONSTRUCT")

    this.database.getCurrentUserData().pipe(
      takeUntil(this._unsubscribeAll),
      skipWhile(user => user === undefined),
      take(1)
    ).subscribe(
      (user) => {
        if (user) {
          // User is logged in, request
          this.requestPermission()
          this.getDeviceId(user.multiFactor.user.uid)
        }
      }
    )

  }

  getDeviceId(uid: string) { 
    // var du = new DeviceUUID().parse();
    // var dua = [
    //     du.pixelDepth,
    //     du.isTouchScreen,
    //     du.os,
    //     du.platform,
    //     du.browser
    // ];
    this.device = new DeviceUUID().get({uid: uid})
  }

  private _unsubscribeAll: Subject<any>;

  private _hasPermission: boolean = false;
  public get hasPermission(): boolean {
    return this._hasPermission;
  }

  private _isListening: boolean = false;
  public get isListening(): boolean {
    return this._isListening;
  }

  private currentToken: string = undefined

  requestPermission() {
    this.messaging.requestPermission
    .pipe(
      takeUntil(this._unsubscribeAll),
      mergeMap((_) => this.messaging.tokenChanges)
    )
    .subscribe(
      (newToken) => {

        if (newToken == null) {
          // Does not have permission
          this._hasPermission = false;
          this.currentToken = null;
        } else {
          this._hasPermission = true;
          this.currentToken = newToken
          this.database.updateCurrentUserMessagingTokenFor(this.device, this.currentToken)
          this.listenToForegroundNotifications()
        }
      },
      (error) => { console.error(error); },  
    );
  }

  listenToForegroundNotifications() {

    if (this.isListening)
      return;

    // console.log("start listening...")

    this.messaging.messages
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((message: any) => {
        // console.log(message);
        this.snackbar.openFromComponent(NotificationSnackBarComponent, {data: message, verticalPosition: 'top', horizontalPosition: 'right'})
        //   () => {
        //     this.router.navigateByUrl(message?.data?.action_url?.replace(/^.*\/\/[^\/]+/, ''))
        //   }
        // )
      });

    this._isListening = true;
  }


  removeToken() {
    this.messaging.deleteToken(this.currentToken)
    return this.database.deleteCurrentUserMessagingTokenFor(this.device)
  }

  ngOnDestroy(): void {
    // console.log("DESTROYED NOTIFICATIONSSERVICE")
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }
}



export interface ChannelledNotification {
  title: string,
  body: string,
  action: string,
  channel: typeof CHANNEL
}

export const CHANNEL = {
  chats: "channel-chats"
}