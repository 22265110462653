
<div class="app-login page"
fxLayout="column nowrap"
fxLayoutAlign="center center">


	<img class="logo-image" src="/assets/logo/logo-yellow-100.png"/>


	<h1 class="mt-16 mb-0 mat-headline">You've got mail</h1>

	<p class="mt-6 m-0 align-center">
		To verify your e-mail address, follow these steps:
	</p>

	<ol fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center" class="p-0 pl-16" style="opacity: .64;">
		<li class="mb-4">Open the verification e-mail that has been sent to your e-mail address</li>
		<li class="mb-4">Click on the verification link in the email and wait for the confirmation</li>
		<li class="mb-4">Return to the Capture Me app and click "verify e-mail address" below</li>
	</ol>

	<button mat-raised-button color="primary" type="submit" class="mw-170 mt-16 mb-24" (click)="verifyAgain()">Verify e-mail address</button>


	<mat-divider class="w-120"></mat-divider>

	<p class="mt-24 mb-0 mat-small">Did you lose the mail, or want us to send it again?</p>
	<a mat-button (click)="resendEmail()" class="secondary-button mb-24">Resend verification e-mail</a>


	<mat-divider class="w-120"></mat-divider>

	<p class="mt-24 mb-0 mat-small">Want to change account?</p>
	<a mat-button routerLink="/logout" class="secondary-button">Logout</a>


</div>