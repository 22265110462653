<!-- <app-overlay-close [sheetRef]="this._bottomSheetRef" [icon]="'check'"></app-overlay-close> -->


<mat-toolbar>
	<div class="width-container toolbar-items">
		
		<div class="titles">
			<span class="title">Crop Profile Picture</span>
		</div>

		<button mat-button color="primary" (click)="_bottomSheetRef.dismiss()">
			Apply
		</button>

	</div>
</mat-toolbar>

<div class="content p-16 align-center">

	<image-cropper
		[aspectRatio]="data.aspectRatio"
		[imageFile]="data.image"
		[maintainAspectRatio]="true"
		format="jpeg"
		[roundCropper]="isRound"
		(imageCropped)="imageCropped($event)"
		(imageLoaded)="imageLoaded()"
		(cropperReady)="cropperReady()"
		(loadImageFailed)="loadImageFailed()"
	></image-cropper>

</div>


