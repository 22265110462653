import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private fAuth: AngularFireAuth
  ) { }


  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.notLoggedInYet();
  }
  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.notLoggedInYet();
  }


  notLoggedInYet() : Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      this.fAuth.user.pipe(take(1)).toPromise().then(
        user => {
          if (!user) {

            // User isn't logged in, can activate
            return resolve(true)

          } else {

            return resolve(this.router.createUrlTree(['home']))
          }
        }
      )
    })
  }


}
