

<form [formGroup]="form" (ngSubmit)="saveEditedProfile()">


	<div class="picture-field-wrapper mb-16" fxLayout="row nowrap">

		<app-profile-picture fxFlex="0 0 100px" class="mr-16" [src]="selectedImageSrc" (click)="pictureInput.click()" [type]="user?.data?.user_type"></app-profile-picture>

		<div class="picture-field" fxFlex="1 1 auto" fxLayout="column nowrap" fxLayoutAlign="center flex-start">

			<input type="file" display="hidden" aria-hidden style="visibility: hidden; display: none;" accept="image/png, image/jpeg"
				#pictureInput
				(change)="profilePictureChanged($event)">
			
			<input type="hidden" formControlName='picture'>

			<app-heading no-top-margin>Profile picture</app-heading>

			<div fxLayout="row wrap" fxLayoutAlign="space-between">
				<button type="button" *ngIf="!selectedImageSrc" mat-button color="primary" (click)="pictureInput.click()">
					<mat-icon>add</mat-icon>
					Choose
				</button>
				<button type="button" *ngIf="selectedImageSrc" mat-button color="primary" (click)="pictureInput.click()">
					<mat-icon>change_circle</mat-icon>
					Change
				</button>
				<button type="button" *ngIf="selectedImageSrc" mat-button color="warn" (click)="cancelCropping()">
					<mat-icon>delete</mat-icon>
					Remove
				</button>
			</div>


		</div>
	</div>
	

	<mat-form-field>
		<mat-label>Username</mat-label>
		<input matInput formControlName="username" required>

		<mat-error *ngIf="fs.showError(form, 'username', 'required')"     >This field is required</mat-error>
		<mat-error *ngIf="fs.showError(form, 'username', 'maxlength')"    >This username is too long (max. 50 characters)</mat-error>
		<mat-error *ngIf="fs.showError(form, 'username', 'usernameInUse')">This username has already been taken</mat-error>

		<mat-hint>Your username is a unique name that everyone can see.</mat-hint>
	</mat-form-field>


	<mat-form-field>
		<mat-label>About me / Bio</mat-label>
		<textarea matInput mat-autosize matAutosizeMinRows="5" formControlName="about_me" (keydown)="form.get('about_me').markAsTouched()"></textarea>

		<mat-hint [class.warn-fg]="fs.showError(form, 'about_me', 'maxlength')" >{{form.get('about_me').value.length}} / 150 characters  -  Your About Me / Bio is public. You can also leave this field empty.</mat-hint>

	</mat-form-field>

	<app-heading>My {{userTypeItems}}</app-heading>

	<app-chips-autocomplete
		[items]="allChips"
		formControlName="user_type_items"
		label="Add {{userTypeItemUnit}}...">

	</app-chips-autocomplete>


	<app-heading>General information (optional)</app-heading>

	<mat-form-field>
		<mat-label>Gender</mat-label>
		<mat-select formControlName="gender">
			<mat-option value="Male">
				Male
			</mat-option>
			<mat-option value="Female">
				Female
			</mat-option>
			<mat-option value="Other">
				Other
			</mat-option>
			<mat-option value="">
				Prefer not to say
			</mat-option>
		</mat-select>
	</mat-form-field>

	<mat-form-field>
		<mat-label>Birthday (dd-mm-yyyy)</mat-label>
		<input matInput [matDatepicker]="picker" formControlName="birthdate">

		<button mat-icon-button type="button" matSuffix (click)="picker.open()">
			<mat-icon>calendar_today</mat-icon>
		</button>

		<mat-datepicker touchUi #picker startView="multi-year"></mat-datepicker>
	</mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Country</mat-label>
    <input type="text"
           aria-label="Country"
           matInput
           formControlName="country"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredCountries | async" [value]="option.text">
        {{option.text}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>


	<div class="align-center">
		<button mat-raised-button color="primary" type="submit" class="mw-170 mt-12">{{editMode === 'register' ? "Save profile, let's go" : "Save profile"}}</button>
	</div>

</form>