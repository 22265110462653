import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlappingDatesDialog } from './overlapping-dates-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ListItemModule } from '../../list-item/list-item.module';
import { MatIconModule } from '@angular/material/icon';
import { ApplicationPipesModule } from 'src/app/pipes/pipes.module';
import { MatCommonImportsModule } from 'src/app/modules/mat-common.module';



@NgModule({
  declarations: [
    OverlappingDatesDialog
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    ListItemModule,
    MatIconModule,
    ApplicationPipesModule,
    MatCommonImportsModule
  ],
  exports: [
    OverlappingDatesDialog
  ],
})
export class OverlappingDatesDialogModule { }
