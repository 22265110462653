<app-toolbar-scaffold class="scaffold">

  <mat-toolbar color="primary" scaffold-header>
		<div class="toolbar-items width-container">
			
			<div class="titles">
				<span class="title">Finish your account</span>
				<span class="subtitle">As a {{userType}}</span>
			</div>

		</div>
  </mat-toolbar>

	<div scaffold-content class="width-container p-16 pb-24">
		

		<p><strong>Welcome to Capture Me, {{email}}!</strong> Before we can start, we would like to get to know you better.</p>

		<p>This information will be displayed on your public profile. <strong>All fields are optional, you can leave them empty if you want.</strong> You can always change this later.</p>


		<app-edit-profile editMode="register" (dirty)="formIsDirty = $event"></app-edit-profile>


	</div>


</app-toolbar-scaffold>


