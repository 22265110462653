// application-pipes.module.ts
// other imports
import { NgModule } from '@angular/core';
import { EllipsisPipe } from './ellipsis.pipe';
import { FirestoreDatePipe, MomentDatePipe, SimpleDatePipe, TimestampDatePipe } from './firestore-date.pipe';
import { JoinAttributePipe } from './join-attribute.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    FirestoreDatePipe,
    MomentDatePipe,
    SimpleDatePipe,
    TimestampDatePipe,
    EllipsisPipe,
    JoinAttributePipe
  ],
  exports: [
    FirestoreDatePipe,
    MomentDatePipe,
    SimpleDatePipe,
    TimestampDatePipe,
    EllipsisPipe,
    JoinAttributePipe
  ]
})
export class ApplicationPipesModule {}