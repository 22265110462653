import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { RegisterFinishComponent } from '../modules/authentication/register-finish/register-finish.component';
import { VerifyEmailComponent } from '../modules/authentication/verify-email/verify-email.component';
import { DatabaseService } from '../services/database.service';
import { User, UserType } from '../types/user.type';
import { PHOTOGRAPHER_BLACKLIST, PHOTOGRAPHER_HOME, SPORTS_LOVER_BLACKLIST, SPORTS_LOVER_HOME } from './account-type-routes';

@Injectable({
  providedIn: 'root'
})
export class AuthStateGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private database: DatabaseService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.isLoggedIn(route, state);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn(childRoute, state);
  }


  isLoggedIn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean | UrlTree> {
    return new Promise((resolve) => {

      this.database.getCurrentUserData().pipe(
        first()
      ).pipe(
        tap(user => {
          if (user) {
            // User has a response, is logged in

            if (!user.multiFactor.user.emailVerified || !user.data.finished_registration) {
              // User hasn't finished verification or registration
              
              if (!user.multiFactor.user.emailVerified) {
                if (route.component !== VerifyEmailComponent) {
                  // Email not verified,
                  // Is not navigating to the Verify component,
                  // go there!
                  return resolve(this.router.createUrlTree(['verify-email']))
                } else {
                  // Email not verified,
                  // Is navigating to Verify component,
                  // Continue
                  return resolve(true)
                }
              }

              if (!user.data.finished_registration) {
                if (route.component !== RegisterFinishComponent) {
                  // User has not finished his/her account,
                  // not navigating to the Finish component,
                  // go there!
                  return resolve(this.router.createUrlTree(['register', 'finish']))
                } else {
                  // User has not finished his/her account,
                  // Is navigating to Finish component,
                  // Continue
                  return resolve(true)
                }
              }
            } else {

              if (route.component === VerifyEmailComponent || route.component === RegisterFinishComponent) {
                // User is trying to go to the verify or finish registration component
                // Both are already done
                // Go home
                return resolve(this.router.createUrlTree(['home']))
              }

            }

            // User is logged in and verified, check if he is trying to access blacklisted url
            if (this.isNavigatingToBlacklistedUrl(user, state)) {
              // Is illegal, send him home
              return resolve(this.router.createUrlTree(['home']))
            }

            // User is ready!
            return resolve(true)

          } else {
            // No user found, to login
            return resolve(this.router.createUrlTree(['login']))
          }
        })
      ).toPromise().then((res) => {
        
      }, (error) => {
        return resolve(this.router.createUrlTree(['login']))
      })
    })
  }

  getUserTypeHome(user: User) {
    switch (user.data.user_type) {

      case UserType.SPORTS_LOVER:
        return SPORTS_LOVER_HOME;

      case UserType.PHOTOGRAPHER:
        return PHOTOGRAPHER_HOME;
    }
  }


  isNavigatingToBlacklistedUrl(user: User, state: RouterStateSnapshot) {

    let blackList: string[] = []

    switch (user.data.user_type) {
      case UserType.SPORTS_LOVER:
        blackList = SPORTS_LOVER_BLACKLIST
        break;

      case UserType.PHOTOGRAPHER:
        blackList = PHOTOGRAPHER_BLACKLIST
        break;
    }
    
    let isIllegal = false;

    blackList.forEach((url) => {
      if (state.url.includes(url, 1)) {
        isIllegal = true
      }
    })

    return isIllegal;

  }


}
