
	<h1 mat-dialog-title>Overlapping Check-ins</h1>
	<mat-dialog-content>
		<p>You are already have {{data.checkIns.length}} check-in{{data.checkIns.length > 1 ? 's' : ''}} on this date. The overlapping check-in{{data.checkIns.length > 1 ? 's are' : ' is'}}:</p>

		<app-list-item type="check-in" *ngFor="let checkIn of data.checkIns" (click)="onCheckInClicked(checkIn)" style="cursor: pointer">

			<span item-title>
				{{checkIn.event ? "At Event - " + checkIn.event?.title : checkIn.location?.geocoded?.locality}}
			</span>

			<span item-content icon-with-text>
				<mat-icon inline="true">calendar_today</mat-icon>
				{{checkIn.date | fsDate: 'L'}}
			</span>

			<div item-suffix fxFlexAlign="center" class="pr-12">
				<button mat-icon-button class="mat-elevation-z2 ml-8 small-button" [class.primary]="checkIn.event" [class.accent]="!checkIn.event">
					<mat-icon *ngIf="!checkIn.event">map</mat-icon>
					<mat-icon *ngIf="checkIn.event">today</mat-icon>
				</button>
			</div>

		</app-list-item>

	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button (click)="_dialogRef.close(true)">Change dates</button>
		<button mat-button color="primary" (click)="_dialogRef.close(false)">Ok</button>
	</mat-dialog-actions>