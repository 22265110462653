import { Pipe } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe {
  transform(val: string, length: number) {
    if (length === undefined || !val) {
      return val;
    }

		let parts = val.split(" ")

    if (parts.length > length) {
			return val.split(" ", length).join(" ") + "..."
    } else {
      return val;
    }
  }
}