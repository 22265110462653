import { formatNumber } from "@angular/common";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})

export class FormService {

	constructor (
	) { }


	showError(form: UntypedFormGroup, key: string, error: string, groupError: boolean = false) : boolean {
		
		try {

			if (!groupError) {
				return (form.get(key).touched || form.get(key).dirty) && form.get(key).hasError(error);
			} else {

				if ((form.get(key).touched || form.get(key).dirty) && form.hasError(error)) {
					form.get(key).setErrors({"genericError": "genericError", ...form.get(key).errors})
					return true
				} else {
					form.get(key).setErrors(null)
					return false
				}

			}
			
		} catch (error) {
			console.error(`FormControl: '${key}' wasn't found in FormGroup: ${JSON.stringify(form.value)}`, error)
			return false;
		}
	}


	passwordsMatchValidator(form: UntypedFormGroup) {
    if (form.get('password') && form.get('password_confirmation')) {
      return form.get('password').value === form.get('password_confirmation').value ? null : { 'mismatch': true };
    }
    return null;
  }

}