

<div class="toolbar-scaffold-wrapper" fxLayout="column nowrap" fxFlex="0 0 100%"
	[class.backdrop-focussed]="backdropFocussed">

	<div class="header-toolbar-wrapper" fxFlex="0 0 auto" #toolbarWrapper>
		<ng-content select="[scaffold-header]"></ng-content>
	</div>


	<div class="content-wrapper"

		fxFlex="1 1 auto" 
		fxLayout="column nowrap" 
		#scrollContainer
		[style.overflow-y]="loading ? 'hidden' : 'auto'"
		>

		<div class="content-backdrop" #contentBackdrop [style.height]="backdropHeight" [style.min-height]="backdropHeight">
			<ng-content select="[scaffold-content-backdrop]"></ng-content>
		</div>


		<div class="scroll-content"
			(click)="backdropFocussed = false; backdropFocussedChange.emit(false)"
			fxLayout="column nowrap" 
			fxLayoutAlign="stretch center"
			[class.scroll-vertical-center]="alignContent === 'center'"
			
			fxFlex="{{scrollableContent ? '0 0 auto' : '1 1 100%'}}">

			<ng-content select="[scaffold-content]"></ng-content>

			<div class="fab-spacing-bottom" *ngIf="fabWrapper.childNodes.length > 0 && fabWrapper.childNodes[0]?.classList?.contains('scaffold-fab')"></div>

		</div>


		<div class="loading-overlay" [class.showing]="loading" fxLayout="column nowrap" fxLayoutAlign="center center" [style.top.px]="scrollContainer.scrollTop">
			<!-- <mat-progress-bar mode="indeterminate" value="0" color="primary"></mat-progress-bar> -->
			
			<mat-spinner color="primary" diameter='48' *ngIf="!progress"></mat-spinner>

			<div class="width-container align-center p-16" *ngIf="progress">
				<div fxLayout="row" fxLayoutAlign="center center" class="mb-16">
					<mat-spinner color="primary" diameter='16' strokeWidth="3" class="mr-8"></mat-spinner>
					<p class="mb-0">{{progress?.state}}</p>
				</div>
				<mat-progress-bar [value]="progress?.value"></mat-progress-bar>
			</div>
			
		</div>

	</div>


	<div class="footer-toolbar-wrapper" fxFlex="0 0 auto">

		<ng-content select="[scaffold-footer]"></ng-content>

	</div>


	<div class="fab-wrapper" fxLayoutAlign="flex-end flex-end" #fabWrapper>

		<ng-content select="[scaffold-fab]"></ng-content>

	</div>

</div>