import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListItemComponent implements OnInit {

  constructor() { }

  @Input() type: 'event' | 'user' | 'album' | 'check-in' = 'user'
  @Input() compact: boolean = false

  ngOnInit(): void {
  }

}
