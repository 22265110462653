import { DocumentReference, CollectionReference, DocumentData, QueryDocumentSnapshot } from "@angular/fire/compat/firestore";
import * as moment from "moment";
import { Album } from "./album.type";
import { UserData } from "./user.type";

export class ChatType {
	id?: string;

	recipients: DocumentReference<UserData>[] // should have length >? 2
	last_message: MessageType

	dialogRef?: CollectionReference<DocumentData>

	unread_by: DocumentReference<UserData>[] // should be length 1, except for when a message is added by system
	is_viewing: DocumentReference<UserData>[]
	removed_by?: DocumentReference<UserData>[]
	messages?: CollectionReference<MessageType>

}


export interface MessageType {
	id?: string,
	text?: string,
	timestamp?: moment.Moment | Date | any;

	from: DocumentReference<UserData>

	about?: AboutAlbum

	removed?: boolean
}

export interface AboutAlbum {
	ref: DocumentReference<Album>,
	album_title: string,
	pictures: string[]
}


// Classes
export class Chat {
	private _chat: ChatType;
	private _currentUserId: string;
	private _contact: UserData;
	private _lastMessage: Message;
	private _isExisting: boolean = true
	private ref: DocumentReference;

	constructor(currentUserId: string, chat: ChatType, ref: DocumentReference) {
		this._currentUserId = currentUserId
		this.ref = ref
		this.update(chat)
	}

	update(chat: ChatType) {
		this._chat = chat
		this._lastMessage = chat.last_message != undefined ? new Message(this._currentUserId, chat.last_message) : undefined
	}

	setIsExisting(isExisting: boolean) {
		this._isExisting = isExisting
	}
	get isExisting() {
		return this._isExisting
	}

	get id() {
		return this._chat.id
	}

	data() {
		return this._chat
	}
	currentUserId() {
		return this._currentUserId
	}


	// Functions
	contactRef() {
		const asRecipient = this._chat.recipients.find(rec => rec.id !== this._currentUserId)
		const asRemoved = this._chat.removed_by?.find(rem => rem.id !== this._currentUserId)

		return asRecipient ? asRecipient : asRemoved
	}
	contact() {
		return this._contact
	}
	contactHasRemoved() {
		const hasRemoved = this._chat.removed_by?.find(rem => rem.id !== this._currentUserId)
		return hasRemoved ? true : (this.contact().is_deleted)
	}
	setContactData(user: UserData) {
		this._contact = user
	}
	setData(chat: ChatType) {
		this._chat = chat;
	}

	isRead() : boolean {
		return this._chat.unread_by ? 
			this._chat.unread_by.findIndex((userRef) => userRef?.id === this._currentUserId) < 0 : 
			true
	}
	contactRead() : boolean {
		return this._chat.unread_by ? 
			this._chat.unread_by.findIndex((userRef) => userRef?.id === this._contact.id) < 0 : 
			true
	}
	contactViewing() : boolean  {
		return this._chat.is_viewing ? 
			this._chat.is_viewing.findIndex((userRef) => userRef?.id === this._contact.id) >= 0 : 
			false
	}
	setReadByUser() {
		this.ref.update({
			unread_by: [
				...this._chat.unread_by.filter((user) => user.id !== this._currentUserId)
			]
		})
	}
	startViewing(userRef: DocumentReference<UserData>) {
		let isViewing = this._chat.is_viewing !== undefined ? this._chat.is_viewing : []
		let existingIndex = this._chat.is_viewing?.findIndex((user) => user.id === userRef.id)

		if (existingIndex >= 0)
			return;

		isViewing.push(userRef)

		this.ref.update({
			is_viewing: isViewing,
		})
	}
	stopViewing() {
		this.ref.update({
			is_viewing: [
				...this._chat.is_viewing?.filter((user) => user.id !== this._currentUserId)
			],
		})
	}

	removeChat(userRef: DocumentReference<UserData>) {
		let removedByList = this._chat.removed_by !== undefined ? this._chat.removed_by : []
		let recipientsList = this._chat.recipients

		// Add to removed_by and remove from recipients
		removedByList.push(userRef)
		recipientsList.splice(
			recipientsList.findIndex((rec) => rec.id === userRef.id), 1
		)

		if (recipientsList.length > 0 && !this.contact().is_deleted) {
			this.ref.update({
				removed_by: removedByList,
				recipients: recipientsList,
			})
		} else {
			this.setIsExisting(false)
			this.ref.delete()
		}
	}

	lastMessage() {
		return this._lastMessage
	}
	setLastMessage(message: Message) {
		this._lastMessage = message
	}

	get hasDialogRef() : boolean  {
		return this._chat.dialogRef ? true : false
	}

	getDialog(startDoc?: QueryDocumentSnapshot<MessageType>, limit: number = 15) {
		if (startDoc) {
			return this._chat.dialogRef?.limit(limit).orderBy('timestamp', 'desc').startAfter(startDoc)
		} else {
			return this._chat.dialogRef?.limit(limit).orderBy('timestamp', 'desc')
		}
	}

	removeLastMessage() {
		this.ref.update({
			last_message: {
				...this.lastMessage().data
			}
		})
	}

	sendMessage(message: Message) {
		const newMessage = {
			...message.data,
			about: message.about || null,
			timestamp: moment().toDate()
		}
		this.ref.set({
			recipients: this._chat.recipients,
			last_message: newMessage,
			unread_by: this.contactViewing() ? [] : [this.contactRef()]
		}, {merge: true}).then((val) => {
			this._chat.dialogRef = this.ref.collection('dialog')
			this._chat.dialogRef.add(newMessage)
		})

	}

}

export class Message {
	public data: MessageType
	private ref: DocumentReference<DocumentData>
	private _currentUserId: string;

	constructor(currentUserId: string, message: MessageType, ref?: DocumentReference<DocumentData>) {
		this.data = message
		this.ref = ref
		this._currentUserId = currentUserId
	}

	update(msg: MessageType) {
		this.data = msg
	}

	get text() {
		return !this.removed ? this.data.text : "This message was deleted."
	}

	get longTime() {
		let date = moment.unix(this.data.timestamp.seconds)
		if (date.isAfter(moment({hour: 23, minute: 59, second: 59}).subtract(1, 'days')) ) {
			return date.format("LT")
		} else
		if (date.isAfter(moment({hour: 23, minute: 59, second: 59}).subtract(2, 'days'))) {
			return "Yesterday"
		} else {
			return date.format("L")
		}
	}

	get removed() {
		return this.data.removed ? true : false
	}
	
	get time() {
		return moment.unix(this.data.timestamp.seconds).format('LT')
	}

	get who() : 'me' | 'other' {
		return this.data.from?.id === this._currentUserId ? 'me' : 'other'
	}

	get isMe() {
		return this.who === 'me'
	}
	get contact() {
		return this.who === 'other'
	}

	get about() {
		return this.data.about
	}


	// Functions
	remove(): Promise<void> {
		this.update({
			...this.data,
			removed: true,
			text: null,
			about: null
		})

		return this.ref?.update(this.data)
	}

	setText(text: string) {
		this.data.text = text
	}

}