import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListItemComponent } from './list-item.component';
import { ProfilePictureModule } from '../profile-picture/profile-picture.module';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { ApplicationPipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [
    ListItemComponent
  ],
  imports: [
    CommonModule,

    MatDividerModule,
    MatRippleModule,
    FlexLayoutModule,
    MatIconModule,
    ApplicationPipesModule
  ],
  exports: [
    ListItemComponent
  ]
})
export class ListItemModule { }
