import { AfterViewInit, ElementRef, EventEmitter, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ToolbarScaffoldService } from './toolbar-scaffold.service';

@Component({
  selector: 'app-toolbar-scaffold',
  templateUrl: './toolbar-scaffold.component.html',
  styleUrls: ['./toolbar-scaffold.component.scss'],
})
export class ToolbarScaffoldComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(
    private _router: Router,
    private service: ToolbarScaffoldService,
  ) {
    this._unsubscribeAll = new Subject();

    this.service.setScaffold(this)
  }
  private _unsubscribeAll: Subject<any>;

  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @ViewChild('contentBackdrop') contentBackdrop: ElementRef;

  @Input() alignContent: 'flex-start' | 'center' | 'flex-end' = 'center'
  @Input() backdropFocussed: boolean = false;
  @Input() backdropHeight: string = "auto";
  @Input() scrollableContent: boolean = true;

  @Output() backdropFocussedChange = new EventEmitter<boolean>();

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  @Input() loading: boolean = false;
  @Input() progress: {state: string, value: number} = undefined;

  ngOnInit(): void {

    this._router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this._unsubscribeAll)
    )
    .subscribe(() => {
      this.loading = false;
      this.scrollContainer.nativeElement.scroll(0, 0);
    })
  }


  ngOnChanges(changes: SimpleChanges): void {
    // Detect when focus changes: scroll to top when necessary
    if (this.backdropFocussed) {
      this.scrollContainer.nativeElement.scroll(0, 0)
    }
  }

  scrollCallback(event) {
    // console.log(event)
  }

  ngAfterViewInit(): void {
    // fromEvent(this.contentBackdrop.nativeElement, 'resize').pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe(evt => {
    //   this.scrollContainer.nativeElement.style.paddingTop = this.contentBackdrop.nativeElement.offsetHeight + 'px'
    // })
    // this.scrollContainer.nativeElement.style.paddingTop = this.contentBackdrop.nativeElement.offsetHeight + 'px'
  }

}
