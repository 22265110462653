import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference, DocumentChangeAction, DocumentData, DocumentReference, Query, QueryDocumentSnapshot, QuerySnapshot } from "@angular/fire/compat/firestore";
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { concat, firstValueFrom, fromEventPattern, Observable, of, Subject } from "rxjs";
import { last, map, mergeAll, mergeMap, scan, take, takeUntil, tap } from "rxjs/operators";
import { User, UserData, UserType } from "../types/user.type";
import { Event } from "../types/event.type"
import { uniqBy, uniqWith } from 'lodash'
import * as moment from 'moment'
import { Moment } from "moment";
import { Album } from "../types/album.type";
import { CheckIn, StartAndEnd, } from "../types/check-in.type";
import { NgxPicaErrorInterface, NgxPicaService } from "@digitalascetic/ngx-pica";
import { Chat, ChatType, MessageType } from "../types/chat.type";
import { Report } from "../types/report.type";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import firebase from 'firebase/compat/app';
import { Payment } from "../types/payment.type";

@Injectable({
	providedIn: 'root'
})

export class DatabaseService implements OnDestroy {


	constructor(
		private fb: AngularFirestore,
		private fAuth: AngularFireAuth,
		private fStorage: AngularFireStorage,
		private ngxPica: NgxPicaService,
		private analytics: AngularFireAnalytics
	) {
		this._unsubscribeAll = new Subject<any>()
		this.listenToUserUpdates()
	}

	private _unsubscribeAll: Subject<any>;

	ngOnDestroy(): void {
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	public reInit(): void {
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();

		this._unsubscribeAll = new Subject<any>()
	}


	//#region USERS

	public userId: string;
	private user: User;
	private userData: UserData;

	private currentUserRef: DocumentReference;

	listenToUserUpdates() {
		this.getCurrentUserData().pipe(takeUntil(this._unsubscribeAll)).subscribe()
	}

	getUserRef(id: string): DocumentReference<UserData> {
		return this.fb.collection<UserData>('users').doc(id).ref
	}

	getUserData(id?: string): Observable<UserData> {
		return this.fb.collection<UserData>('users').doc(id ? id : this.user.multiFactor.user.uid).valueChanges({ idField: 'id' })
	}

	async getUserDataFromRef(ref: DocumentReference<UserData>): Promise<UserData> {
		const snapshot = await ref.get();
		return snapshot.data();
	}

	getUserSnapshot(): User {
		return this.user
	}

	currentUserDataObservable: Observable<User>;
	unsubscribeCurrentUserObservable: Subject<any> = new Subject<any>();
	getCurrentUserData(): Observable<User> {
		if (this.currentUserDataObservable !== undefined) {
			return this.currentUserDataObservable
		}

		return this.currentUserDataObservable = this.fAuth.user.pipe(
			mergeMap((fUser) => {

				if (fUser === null) {
					this.unsubscribeCurrentUserObservable.complete()
					this.unsubscribeCurrentUserObservable = new Subject<any>()
					return of(undefined)
				}

				return this.getUserData(fUser.uid).pipe(
					takeUntil(this.unsubscribeCurrentUserObservable),
					takeUntil(this._unsubscribeAll),
					map(user => {
						return { data: user, multiFactor: { user: fUser } }
					})
				)

			}),
			tap((res) => {
				// console.log("Current:", res?.uid)

				if (res === undefined) {
					// user is not logged in

					this.userId = this.user = this.userData = undefined;
					// observer.complete()
					return;
				}



				this.currentUserRef = this.getUserRef(res.multiFactor.user.uid)

				this.userId = res.multiFactor.user.uid
				this.user = res
				this.userData = res.data


			}),
			map((user) => {
				if (user !== undefined) {
					return user
				} else {
					return undefined
				}
			}),
		)


		// this.fAuth.user.pipe(
		// 	tap((fUser) => {

		// 		if (!fUser) {
		// 			observer.next(undefined)
		// 			observer.complete()
		// 			return;
		// 		}

		// 		this.userId = fUser.uid

		// 		this.getUserData(this.userId).pipe(
		// 			takeUntil(this._unsubscribeAll)
		// 		)
		// 		.pipe(
		// 			tap((user) => {

		// 				this.user = {...fUser, data: user}
		// 				this.userData = user

		// 				if (fUser !== undefined && this.userData !== undefined)
		// 					observer.next(this.user)

		// 			})	
		// 		).subscribe()
		// 	})
		// ).subscribe()
		// })
	}
	getCurrentUserDataSnapshot(): User | undefined {
		return this.user
	}


	updateCurrentUser(data: Partial<UserData>): Promise<boolean> {
		return new Promise((resolve, error) => {

			let deletedExistingPicture = data.picture === undefined
			let hasNewPicture = data.picture !== undefined && data.picture !== null

			if (data.birthdate) {
				data.birthdate = moment(data.birthdate).toDate()
			}

			let newPicture;
			// new image
			if (hasNewPicture) {
				newPicture = data.picture;
				// remove picture in data
				data.picture = null;
			} else
				if (deletedExistingPicture) {
					data.picture = null
				} else
					if (!deletedExistingPicture) {
						delete data.picture
					}

			this.updateCurrentUserData(
				{
					...data,
					username_indexed: data.username.toLowerCase(),
					finished_registration: true
				})
				.then((res) => {


					// upload picture
					if (hasNewPicture) {
						this.urltoFile(newPicture, `profie_picture.jpeg`, 'image/jpeg').then(
							(file) => {

								this.compressImages([file], 512).subscribe(
									(event) => {
										if (event.event === CompressEventType.Complete) {
											let compressedImage = event.data[0]

											this.uploadProfileImage(compressedImage).then((res) => {
												resolve(true)
											}, (err) => error(err));

										}
									}
								)
							}
						)
					} else
						if (deletedExistingPicture) {
							this.removeProfilePicture().then((res) => {
								resolve(true)
							})
						} else {
							resolve(true)
						}

					this.fAuth.user.pipe(take(1)).pipe(
						tap((user) => {
							user.updateProfile({
								displayName: data.username
							})
						})
					).subscribe(() => { }, (err) => error(err))

				},
					(err) => {
						error(err)
					}
				)


		})
	}
	updateCurrentUserData(data: Partial<UserData>): Promise<void> {
		return this.currentUserRef.update(data)
	}

	uploadProfileImage(file: File): Promise<boolean> {
		return new Promise((resolve, error) => {

			this.fStorage.ref(`users/${this.userId}/profile_picture.jpg`).put(file).then(
				(res) => {

					res.ref.getDownloadURL().then(
						path => {
							// Update firebase user
							this.fAuth.user.pipe(take(1)).pipe(
								tap((user) => {
									user.updateProfile({
										photoURL: path
									})
								})
							).subscribe(() => { }, (err) => error(err))

							// Update user in database
							this.updateCurrentUserData(
								{
									picture: path
								}
							).then((res) => {
								// resolve
								resolve(true)
							}, (err) => error(err))
						}
					)


				})
		}
		)
	}

	removeProfilePicture(): Promise<boolean> {
		return new Promise((resolve, error) => {
			this.fAuth.user.pipe(take(1)).pipe(
				tap((user) => {
					if (user.photoURL)
						this.fStorage.refFromURL(user.photoURL).delete()

					user.updateProfile({
						photoURL: null
					})
					resolve(true)
				})
			).subscribe(() => { }, (err) => error(err))
		})
	}


	updateCurrentUserMessagingTokenFor(deviceUuid: string, token: string): Promise<void> {
		// Only happen when user finished registration
		if (!this.userData?.finished_registration) {
			return Promise.resolve();
		}

		let newTokenList = this.userData?.messaging_tokens || []
		let oldIndex = newTokenList.findIndex(t => t.uuid === deviceUuid)


		if (oldIndex < 0) {
			// Search for token instead of uuid
			oldIndex = newTokenList.findIndex(t => t.token === token)
		}

		const newItem = {
			uuid: deviceUuid,
			token: token
		}

		if (oldIndex < 0) {
			// push
			newTokenList.push(newItem)
		} else {
			// clean up old records
			newTokenList = newTokenList.filter(t => t.token !== token && t.uuid !== deviceUuid)
			newTokenList.push(newItem)
		}

		// console.log("N:", newToken); console.log("O:", oldToken); console.log("L:", this.userData?.messaging_tokens, "TL:", newTokenList)
		return this.currentUserRef ? this.currentUserRef.update({
			messaging_tokens: newTokenList
		}) : Promise.resolve()
	}

	deleteCurrentUserMessagingTokenFor(deviceUuid: string) {
		// Only happen when user finished registration
		if (!this.userData?.finished_registration) {
			return Promise.resolve();
		}

		let newTokenList = this.userData?.messaging_tokens || []
		let oldIndex = newTokenList.findIndex(t => t.uuid === deviceUuid)

		if (oldIndex < 0) {
			// nothing
		} else {
			newTokenList.splice(oldIndex, 1)
		}

		return this.currentUserRef ? this.currentUserRef.update({
			messaging_tokens: newTokenList
		}) : Promise.resolve()
	}

	//#endregion


	//#region USER TYPE ITEMS
	getUserTypeItems(userType: typeof UserType | number): Observable<any> {
		return this.fb.collection('user_type_items').doc<any>(userType.toString()).collection('items', ref => ref.orderBy('title')).valueChanges({ idField: 'id' })
	}
	getUserTypeItemsForArray(items: string[], userType: number): Promise<string[]> {
		return new Promise((resolve) => {
			this.getUserTypeItems(userType).pipe(
				take(1)
			).subscribe((res) => {
				let result = res
				result.filter((item) => items?.indexOf(item.id) > -1)


				resolve(res.filter((item) => items?.indexOf(item.id) > -1).map((item) => item?.title))
			})
		})
	}
	//#endregion


	//#region EVENTS

	getNewOrExistingEventDocument(eventId?: string): AngularFirestoreDocument<Event> {
		return this.fb.collection<Event>('events').doc(eventId)
	}

	getEventsForDate(date: Moment): Observable<Event[]> {
		return this.fb.collection<Event>('events',
			(ref) => ref.where('date.dates', 'array-contains', date.utc().startOf('date').toDate()))
			.valueChanges({ idField: 'id' })
	}

	getEventsForRange(start: Moment, end: Moment): Observable<Event[]> {
		return this.fb.collection<Event>('events',
			(ref) => ref.where('date.dates', 'array-contains-any', this.createRangeDaysArray(start, end)).orderBy('date.start'))
			.valueChanges({ idField: 'id' })
	}

	getEvent(id: string): Observable<Event> {
		return this.fb.collection<Event>('events').doc(id).valueChanges({ idField: 'id' })
	}

	getEventRef(id: string) {
		return this.fb.collection<Event>('events').doc(id).ref
	}

	getUpcomingEventsList(startAfter?: Event): Promise<Event[]> {
		return firstValueFrom(this.fb.collection<Event>('events', (ref) => {
			let today = moment().endOf('date').utc().toDate();
			return ref.where('date.start', '>', today).orderBy('date.start', 'asc').startAfter(startAfter?.date?.start ?? today).limit(5)
		}).get({ source: "server" }).pipe(map((snapshot) => snapshot.docs.map((doc) => {
			let data = doc.data()
			data.id = doc.id
			return data;
		}))));
	}
	getPastEventsList(startAfter?: Event): Promise<Event[]> {
		return firstValueFrom(this.fb.collection<Event>('events', (ref) => {
			let yesterday = moment().subtract(1, 'day').endOf('date').utc().toDate();
			return ref.where('date.end', '<', yesterday).orderBy('date.end', 'desc').startAfter(startAfter?.date?.end ?? yesterday).limit(5)
		}).get({ source: "server" }).pipe(map((snapshot) => snapshot.docs.map((doc) => {
			let data = doc.data()
			data.id = doc.id
			return data;
		}))));
	}

	//#endregion


	//#region PAYMENTS/PURCHASES


	getAllPurchasesForUser(): Observable<Payment[]> {
		return this.fb.collection<Payment>('payments',
			(ref) => ref.where("buyer", "==", this.getUserRef(this.userId))
			.orderBy("created", "desc")
		).valueChanges()
	}
	getAllSalesForUser(): Observable<Payment[]> {
		return this.fb.collection<Payment>('payments',
			(ref) => ref
			.where("merchant", "==", this.getUserRef(this.userId))
			.orderBy("created", "desc")
		).valueChanges()
	}

	getPayment(id: string): Observable<Payment> {
		return this.fb.collection<Payment>("payments").doc(id).valueChanges();
	}

	//#endregion


	//#region CHECK-INS

	getCheckInRef(id: string): DocumentReference<CheckIn> {
		return this.fb.collection<CheckIn>('check-ins').doc(id).ref
	}

	eventCheckInsFilter<T>(ref: CollectionReference<T>, eventId: string, filterUserType: number) {
		return ref
			.where('user_type', '==', filterUserType)
			.where('linked_event', "==", this.fb.collection('events').doc(eventId).ref)
	}
	eventCheckInsCollection(eventId: string, filterUserType: number): AngularFirestoreCollection<CheckIn> {
		return this.fb.collection<CheckIn>('check-ins', (ref) => {
			return this.eventCheckInsFilter<DocumentData>(ref, eventId, filterUserType)
		})
	}
	eventCheckInsQuery(eventId: string, filterUserType: number): Query<CheckIn> {
		return this.eventCheckInsFilter<CheckIn>(this.fb.collection<CheckIn>('check-ins').ref, eventId, filterUserType)
	}

	getEventCheckIns(eventId: string, filterUserType: number): Observable<CheckIn[]> {
		return this.eventCheckInsCollection(eventId, filterUserType).valueChanges({ idField: 'id' });
	}
	getEventCheckInSnapshots(eventId: string, filterUserType: number): Observable<DocumentChangeAction<CheckIn>[]> {
		return this.eventCheckInsCollection(eventId, filterUserType).snapshotChanges()
	}

	getCurrentUserIsCheckedIn(eventId: string): Observable<CheckIn[]> {
		return this.fb.collection<CheckIn>('check-ins', (ref) => {
			return ref
				.where("user_ref", "==", this.getUserRef(this.user.multiFactor.user.uid))
				.where("linked_event", "==", this.fb.collection("events").doc(eventId).ref)
				.limit(1)
		}).valueChanges({ idField: 'id' })
	}

	getCurrentUserCheckInForEvent(eventId: string): Promise<CheckIn> {
		return firstValueFrom(
			this.getCurrentUserIsCheckedIn(eventId).pipe(
				map((array) => array.length > 0 ? array[0] : null)
			)
		)
	}

	addCurrentUserCheckIn(checkIn: Partial<CheckIn>): Promise<void> {
		checkIn.user_ref = this.getUserRef(this.user.multiFactor.user.uid)

		if (this.userData?.user_type)
			checkIn.user_type = this.userData.user_type

		// Add optional fields as null
		if (!checkIn.linked_event)
			checkIn.linked_event = null
		if (!checkIn.linked_album)
			checkIn.linked_album = null
		if (!checkIn.identification)
			checkIn.identification = null

		this.analytics.logEvent('check_in', { at_event: checkIn.linked_event !== null ? checkIn.linked_event.id : false })
		return this.fb.collection('check-ins').doc().set(checkIn)
	}

	removeCurrentUserCheckIn(checkInId: string): Promise<void> {
		this.analytics.logEvent('check_out')
		return this.fb.collection('check-ins').doc(checkInId).delete()
	}

	getOverlappingCheckIns(startAndEnd: StartAndEnd<Moment>, exclude: string = null): Promise<CheckIn[]> {
		const date = this.createDatesArrayFrom(startAndEnd)

		let queries: Query<CheckIn>[] = date.dates.map(moment =>
			this.fb.collection<CheckIn>('check-ins').ref
				.where('user_ref', '==', this.getUserRef(this.user.multiFactor.user.uid))
				.where('date.dates', 'array-contains', moment)
		);

		if (exclude != null) {
			queries = queries.map(
				(query) => query.where(firebase.firestore.FieldPath.documentId(), '!=', exclude)
			);
		}

		return new Promise((resolve) => {

			Promise.all(
				queries.map((query) => query.get())
			).then(
				(results: QuerySnapshot<CheckIn>[]) => {
					const combined: QueryDocumentSnapshot<CheckIn>[] = [].concat(...results.map(snap => snap.docs))
					const uniqCheckIns = uniqWith(combined, (a, b) => a.id === b.id)

					Promise.all(
						uniqCheckIns.map((doc) => {
							return this.resolveCompleteCheckIn(doc)
						})
					).then(
						(checkIns: CheckIn[]) => {
							// Order check-ins according to docs
							let completeCheckIns = []
							uniqCheckIns.forEach((doc, index) => {
								completeCheckIns.push(checkIns.find(checkIn => doc.id === checkIn.id))
							})
							resolve(completeCheckIns)
						}
					)
				}
			)

		})
	}

	// User check-ins
	getCompleteUserCheckins(userId: string, lastDoc?: QueryDocumentSnapshot<CheckIn>): Promise<{ checkIns: CheckIn[], docs: QueryDocumentSnapshot<CheckIn>[] }> {

		let get: Promise<QuerySnapshot<CheckIn>> = undefined

		if (lastDoc) {
			get = this.fb.collection<CheckIn>('check-ins').ref.where('user_ref', "==", this.getUserRef(userId))
				.orderBy('date.start', 'desc')
				.startAfter(lastDoc)
				.limit(15)
				.get()
		} else {
			get = this.fb.collection<CheckIn>('check-ins').ref.where('user_ref', "==", this.getUserRef(userId))
				.orderBy('date.start', 'desc')
				.limit(15)
				.get()
		}

		return new Promise((resolve) => {
			get.then((snapshot) => {

				Promise.all(
					snapshot.docs.map((doc) => {
						return this.resolveCompleteCheckIn(doc)
					})
				).then(
					(checkIns: CheckIn[]) => {
						// Order check-ins according to docs
						let orderedCheckIns = []
						snapshot.docs.forEach((doc, index) => {
							orderedCheckIns.push(checkIns.find(checkIn => doc.id === checkIn.id))
						})

						resolve({ docs: snapshot.docs, checkIns: orderedCheckIns })
					}
				)

			})
		})
	}

	getUsersLastCheckIn(userId: string): Promise<CheckIn> {
		return new Promise((resolve, error) => {
			this.fb.collection<CheckIn>('check-ins',
				(ref) => ref
					.where('user_ref', "==", this.getUserRef(userId))
					.orderBy("date.start", "desc")
					.limit(1)
			).get().subscribe((res) => {
				if (res.docs.length > 0) {
					this.resolveCompleteCheckIn(res.docs[0]).then(
						(res) => resolve(res)
					)
				} else {
					resolve(undefined)
				}
			})
		})
	}

	resolveCompleteCheckIn(checkInDoc: QueryDocumentSnapshot<CheckIn>): Promise<CheckIn> {
		return new Promise<CheckIn>((resolve) => {
			let completeCheckIn: CheckIn = checkInDoc.data()
			completeCheckIn.id = checkInDoc.id

			// This is an event check-in, get the event and set the "event_name" and "date"
			if (completeCheckIn.linked_event != null) {

				completeCheckIn.linked_event.get().then(
					(event) => {
						completeCheckIn.event = { ...event.data(), id: event.id }
						completeCheckIn.date = event.data().date
						completeCheckIn.location = event.data().location

						resolve(completeCheckIn)
					},
					(err) => {
						resolve(undefined)
					}
				)

			} else {
				resolve(completeCheckIn)
			}
		})
	}

	//#endregion


	//#region NEARBY PHOTOGRPAHERS

	getNearbyPhotographers(date: Moment): Promise<CheckIn[]> {
		return new Promise((resolve) => {
			this.fb.collection<CheckIn>("check-ins").ref
				.where("user_type", '==', UserType.PHOTOGRAPHER)
				.where('date.dates', 'array-contains', date.toDate())
				.orderBy('date.start', 'desc')
				.get().then((snapshot) => {

					let users = uniqBy(snapshot.docs.map(doc => doc.data().user_ref), 'id')
						.filter(user => user !== null)
					let events = uniqBy(snapshot.docs.map(doc => doc.data().linked_event), 'id')
						.filter(event => event !== null)

					Promise.all([
						Promise.all(users.map(user => user.get())),
						Promise.all(events.map(event => event.get()))
					])
						.then(([users, events]) => {

							let result = snapshot.docs
								// .map(doc => doc.data())
								.map((doc) => {
									const checkIn = doc.data()

									if (checkIn.user_ref != null) {
										checkIn.user = { id: checkIn.user_ref.id, ...users.find((user) => user.id == checkIn.user_ref.id).data() }
									}
									if (checkIn.linked_event != null) {
										checkIn.event = { id: checkIn.linked_event.id, ...events.find((event) => event.id == checkIn.linked_event.id).data() }
									}
									return { id: doc.id, ...checkIn }
								})

							resolve(result)

						})

				})
		})
	}

	//#endregion


	//#region ALBUMS
	getAlbumFromRef(ref: DocumentReference<Album>): Promise<QueryDocumentSnapshot<Album>> {
		return ref.get();
	}

	getAlbumsForDate(date: Moment): Observable<Album[]> {
		return this.fb.collection<Album>('albums',
			(ref) => ref.where('date.dates', 'array-contains', date.toDate()))
			.valueChanges({ idField: 'id' })
	}
	getAlbumsForRange(start: Moment, end: Moment): Observable<Album[]> {
		return this.fb.collection<Album>('albums',
			(ref) => ref.where('date.dates', 'array-contains-any', this.createRangeDaysArray(start, end)).orderBy('date.start'))
			.valueChanges({ idField: 'id' })
	}

	getAlbumsForEvent(eventRef: DocumentReference<Event>): Observable<Album[]> {
		return this.fb.collection<Album>('albums', (ref) => ref.where('linked_event', '==', eventRef)).valueChanges({ idField: 'id' })
	}

	getUserAlbums(userId: string, lastDoc?: QueryDocumentSnapshot<Album>, limit: number = 15): Promise<{ albums: Album[], docs: QueryDocumentSnapshot<Album>[] }> {
		let get: Promise<QuerySnapshot<Album>> = undefined

		if (lastDoc) {
			get = this.fb.collection<Album>('albums').ref.where('owner_ref', "==", this.getUserRef(userId))
				.orderBy('date.start', 'desc')
				.startAfter(lastDoc)
				.limit(limit)
				.get()
		} else {
			get = this.fb.collection<Album>('albums').ref.where('owner_ref', "==", this.getUserRef(userId))
				.orderBy('date.start', 'desc')
				.limit(limit)
				.get()
		}

		return new Promise((resolve) => {
			get.then((snapshot) => {

				// Get the events and add them to the albums
				Promise.all(
					snapshot.docs.map(doc => { return { ...doc.data(), id: doc.id } }).map(
						(album) => {
							return this.getAlbumWithEvent(album)
						}
					)
				).then(
					(albumsWithEvents) => {
						resolve({ docs: snapshot.docs, albums: albumsWithEvents })
					}
				)
			})
		})
	}

	getAlbumWithEvent(album: Album): Promise<Album> {
		if (album.linked_event) {
			return new Promise<Album>((resolve) => {
				album.linked_event.get().then(event => {
					resolve({ ...album, event: event.data() })
				})
			})
		} else {
			return Promise.resolve({ ...album, event: undefined })
		}
	}


	getLatestAlbum(userId: string): Promise<Album> {
		return new Promise((resolve) => {
			this.getUserAlbums(userId, undefined, 1).then(
				(res) => {
					resolve(res.albums ? res.albums[0] : undefined)
				}
			)
		})
	}

	getAlbumWithUser(albumId: string) {
		return new Promise<Album>((resolve) => {
			this.getAlbumRef(albumId).get().then((doc) => {
				// Get album with event
				this.getAlbumWithEvent({ ...doc.data(), id: doc.id }).then((album) => {
					album.owner_ref.get().then((userDoc) => {
						resolve(
							{
								...album,
								owner: {
									...userDoc.data(),
									id: userDoc.id
								}
							})
					})
				})
			}
			)
		})
	}

	getAlbumRef(id?: string): DocumentReference<Album> {
		if (id) {
			return this.fb.collection<Album>('albums').doc(id).ref
		} else {
			return this.fb.collection<Album>('albums').doc().ref
		}
	}

	deleteAlbum(album: Album) {
		this.getAlbumRef(album.id).delete()
	}

	//#endregion


	//#region CHATS

	getAllChatsChanges() {

		let userUpdatesUntil: Subject<any> = new Subject<any>()

		let chatsArray: Chat[] = []

		let prevDocs: QueryDocumentSnapshot<ChatType>[] = []

		return fromEventPattern(handler => {
			this.fb.collection<ChatType>('chats').ref
				.where('recipients', 'array-contains', this.getUserRef(this.userId))
				.orderBy('last_message.timestamp', 'desc')
				.onSnapshot(handler, (err) => { }) // To silent errors
		})
			.pipe(
				takeUntil(this._unsubscribeAll),

				map((snapshot: QuerySnapshot<ChatType>) => {

					let removed = prevDocs.filter(pDoc => snapshot.docs.findIndex((doc) => pDoc.id === doc.id) < 0)

					let changes: SnapshotChanges<Chat>[] = []
					snapshot.docs.forEach(
						(doc, index) => {
							let changeEvent: SnapshotChanges<Chat> = {
								oldIndex: -1,
								newIndex: -1,
								type: 'removed',
								data: new Chat(this.userId, {
									...doc.data(),
									id: doc.id,
									dialogRef: this.fb.collection<MessageType>(`chats/${doc.id}/dialog`).ref
								},
									doc.ref
								)
							}

							let isAdded = prevDocs.findIndex(pDoc => pDoc.id === doc.id) < 0
							let isRemoved = removed.findIndex(rDoc => rDoc.id === doc.id) >= 0
							let prevIndex = prevDocs.findIndex((pDoc) => pDoc.id === doc.id)

							if (isAdded) {
								// ADDED
								changeEvent.type = "added"
								changeEvent.newIndex = index
							} else
								if (isRemoved) {
									// REMOVED
									changeEvent.type = "removed"
									changeEvent.oldIndex = prevIndex

								} else {
									// MODIFIED
									// Item exists, not in removed and in prevDocs

									// check changes
									if (prevDocs[prevIndex].isEqual(doc)) {
										return;
									}

									changeEvent.type = "modified"
									changeEvent.oldIndex = prevIndex
									changeEvent.newIndex = index
								}

							changes.push(changeEvent);
						}
					)

					// add removed
					removed.forEach(doc => {
						changes.push({
							oldIndex: prevDocs.findIndex(pDoc => pDoc.id === doc.id),
							newIndex: -1,
							type: "removed",
							data: new Chat(this.userId, { ...doc.data(), id: doc.id }, doc.ref)
						})
					})

					prevDocs = snapshot.docs

					return changes
				}),
				// concatMap(changes => changes),
				mergeMap((changes) => {
					return concat(
						changes.map(
							(change) => {
								if (change.type === "added") {

									return new Promise(resolve => {
										change.data.contactRef().get().then(
											(user) => {
												change.data.setContactData({ ...user.data(), id: user.id })
												resolve(change)
											}
										)
									})

									// // get user
									// return fromEventPattern(
									// 	handler => {
									// 		change.data.contactRef().onSnapshot(handler, (err) => console.log(err))
									// 	}
									// ).pipe(
									// 	take(1),
									// 	map((user: DocumentSnapshot<UserData>) => {
									// 		change.data.setContactData({...user.data(), id: user.id})
									// 		// console.log(change)
									// 		return change
									// 	})
									// )

								} else {

									return Promise.resolve(change)

								}
							}
						)
					)
						.pipe(
							mergeAll(),
							scan((acc, arr) => [...acc, arr], []),
							last(null, [])
						)
				}),

			)

	}


	getChatRef(chatId?: string) {
		return this.fb.collection<ChatType>('chats').doc(chatId).ref
	}

	//#endregion


	//#region PICTURES

	compressImages(images: Array<File>, boxSize: number = 840, quality: number = 2): Observable<CompressEvents> {
		return new Observable<CompressEvents>((observer) => {
			const length = images.length;
			var count = 0;

			var res: Array<File> = [];

			let event = new CompressEvents();
			event.length = length

			if (length <= 0) {
				// No images, resolve immediately
				event.event = CompressEventType.NoImages
				observer.next(event)
				observer.complete()
				return
			}

			this.ngxPica.resizeImages(images, boxSize, boxSize, { exifOptions: { forceExifOrientation: false }, aspectRatio: { keepAspectRatio: true }, quality: quality })
				.subscribe((imageResized: File) => {

					console.log(imageResized.size)

					//Assign the result to variable for setting the src of image element
					res[count] = imageResized;

					event.event = CompressEventType.NextImage
					event.current = count
					event.data = res;

					observer.next(event)

					count++;


					if (count == length) {
						let event = new CompressEvents();
						event.event = CompressEventType.Complete
						event.data = res;

						observer.next(event)
						observer.complete()
					}

				}, (err: NgxPicaErrorInterface) => {
					observer.error(err)
				});

		})
	}

	uploadFullResAlbumPictures(albumId: string, images: File[]) {
		let overalProgress = 0;
		let maxProgress = 100 * (images.length)

		return new Observable<{ perc: number, images?: { url: string, id: string }[] }>(obs => {

			Promise.all([
				...images.map((img) => {

					return {
						ref: this.fb.collection('albums').doc(albumId).collection("full_res_pictures").doc().ref,
						image: img
					}

				})

			]).then((images) => Promise.all([
				...images.map((img) => {
					return this.fStorage.ref(`albums/full/${this.userId}/${albumId}/${img.ref.id}.jpeg`).put(img.image)
				}).map((task, index) => {

					return new Promise<string>(resolve => {

						task.percentageChanges().subscribe((perc) => {
							overalProgress += perc

							obs.next({ perc: Math.round(overalProgress / maxProgress) })
						})

						task.then(async (snapshot) => {

							let url = await snapshot.ref.getDownloadURL();

							await images[index].ref.set({
								url: url
							});

							resolve(url)
						})

					})

				})

			]).then((res) => {

				obs.next({
					perc: 100, images: images.map((img, index) => {

						return {
							url: res[index],
							id: img.ref.id
						}

					}),
				})
				obs.complete()
			})
			)

		});
	}

	uploadAlbumPictures(folderPath: string, images: File[]) {
		let overalProgress = 0;
		let maxProgress = 100 * (images.length)

		return new Observable<{ perc: number, urls?: string[] }>(obs => {

			Promise.all([
				...images.map((img, index) => {
					return this.fStorage.ref(`${folderPath}/${(new Date()).toISOString()}-${index}.jpeg`).put(img)
				}).map((task) => {

					return new Promise<string>(resolve => {

						task.percentageChanges().subscribe((perc) => {
							overalProgress += perc

							obs.next({ perc: Math.round(overalProgress / maxProgress) })
						})

						task.then((snapshot) => {
							resolve(snapshot.ref.getDownloadURL())
						})

					})

				})

			]).then((res) => {
				obs.next({ perc: 100, urls: res })
				obs.complete()
			})

		})

	}

	deleteImage(url: string) {
		// this.fStorage.refFromURL(url).delete()
	}

	deleteFullResImage(albumId: string, id: string) {

		// this.fb.collection('albums').doc(albumId).collection("full_res_pictures").doc(id).get().subscribe(
		// 	(doc) => {
		// 		this.deleteImage(doc.data().url);
		// 		doc.ref.delete();
		// 	}
		// )
	}

	// uploadBase64(path: string, base64: string) : Promise<{success: string, path: string}> {
	// 	return new Promise((resolve, error) => {

	// 		let data = base64.substring(base64.indexOf(',') + 1, base64.length)

	// 		this.fStorage.ref(path).putString(data, 'base64').then((res) => {

	// 			res.ref.getDownloadURL().then((res) => {
	// 				resolve({success: "yes", path: res})
	// 			})

	// 		},
	// 		(err) => {
	// 			console.error(err)
	// 			error(err)
	// 		})

	// 	})
	// }

	urltoFile(url, filename, mimeType) {
		return (fetch(url)
			.then(function (res) { return res.arrayBuffer(); })
			.then(function (buf) { return new File([buf], filename, { type: mimeType }); })
		);
	}

	//#endregion

	//#region REPORTING

	addReport(report: Report) {
		report.reported_by = this.getUserRef(this.userId)
		report.timestamp = new Date()
		report.status = "New"
		return this.fb.collection('reports').doc().set(report)
	}

	//#endregion


	createDatesArrayFrom(dates: StartAndEnd<Moment>): StartAndEnd<Date> {

		let array = []
		let day = moment(dates.start)

		let currentDay = moment(day)
		const endDay = moment(dates.end)

		while (currentDay <= endDay) {
			array.push(moment(day).toDate())

			day.add(1, "day") // move on!
			currentDay = moment(day)
		}

		let result: StartAndEnd<Date> = {
			start: dates.start.toDate(),
			end: dates.end.toDate(),
			dates: array
		}

		return result
	}

	currentWeekDaysArray(today: Moment): Date[] {
		const startOfWeek = moment(today).startOf('isoWeek')

		let result = []

		for (let i = 0; i < 7; i++) {
			result.push(
				moment(startOfWeek).add(i, 'days').toDate()
			)
		}

		return result
	}

	createRangeDaysArray(start: Moment, end: Moment): Date[] {
		let result = []

		const diff = end.diff(start, 'days')

		for (let i = 0; i <= diff; i++) {
			result.push(moment(start).add(i, 'days').toDate())
		}

		return result
	}

}


export class CompressEvents {
	event: CompressEventType;
	current: number;
	length: number;
	data: Array<File>;
}
export enum CompressEventType {
	NextImage = 3001,
	Complete = 3002,
	NoImages = 3003
}


export interface SnapshotChanges<T> {
	oldIndex: number,
	newIndex: number,
	type: "added" | "modified" | "removed"
	data: T
	ref?: DocumentReference
}