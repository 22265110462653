import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowIntroductionGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasSeenIntroduction();
  }
  canActivateChild(
    _childRoute: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasSeenIntroduction();
  }


  hasSeenIntroduction(): boolean | UrlTree {

    const hasSeenIntroduction = localStorage.getItem(HAS_SEEN_INTRODUCTION_KEY) === 'true'

    if (hasSeenIntroduction)
      return true
    else
      return this.router.createUrlTree(['/capture-me-introduction'])
  }
  
}


export const HAS_SEEN_INTRODUCTION_KEY = 'has_seen_introduction'

