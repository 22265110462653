import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { isArray } from 'lodash';

@Pipe({
  name: 'joinAttr',
})
export class JoinAttributePipe implements PipeTransform {
  transform(input: any, attr: string, character: string = ''): any {
    if (!isArray(input)) {
      return input;
    }
    return input.map(item => item[attr]).filter(item => item !== undefined).join(character)
  }
}
