
<div class="chips-autocomplete-wrapper">

	<mat-chip-list #chipList aria-label="Chips list">
		<mat-chip
			*ngFor="let item of selectedItems"
			[selectable]="false"
			[removable]="removable"
			(removed)="remove(item)">
			{{item.title}}
			<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
		</mat-chip>

		<mat-chip *ngIf="selectedItems.length == 0">
			None
		</mat-chip>

	</mat-chip-list>

	<mat-form-field
		class="mt-12"
		[appearance]="appearance"
		(click)="$event.stopPropagation(); trigger.openPanel()"
		[class.has-hint]="getHint() !== ''">

		<mat-label>{{label}}{{required ? ' *' : ''}}</mat-label>
		<input
			matInput
			[disabled]="disabled"
			#itemInput
			#trigger="matAutocompleteTrigger"
			[formControl]="formCtrl"
			[matAutocomplete]="auto"
			(keyDown)="checkSeparatorCodes($event)"/>
	

		<mat-hint *ngIf="getHint() !== ''" class="font-weight-900">{{getHint()}}</mat-hint>
		
	</mat-form-field>

	<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)" (opened)="filterByName()" (closed)="filterByName()">
		<ng-container *ngFor="let item of filteredItems" >
			<mat-option [value]="item.id">
				{{item.title}}
			</mat-option>
		</ng-container>
		<ng-container *ngIf="filteredItems.length <= 0" >
			<mat-option [value]="undefined" [disabled]="true" class="primary" style="height: auto !important;">
				<div style="white-space: normal;">
					No items found
					<div style="font-size: 14px; line-height: 1.75em; margin-bottom: 8px;">
						Try to search for something else
					</div>
				</div>
			</mat-option>
		</ng-container>
	</mat-autocomplete>

</div>

