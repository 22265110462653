import {COMMA, ENTER, TAB} from '@angular/cdk/keycodes';
import {Component, ElementRef, ViewChild, Input, forwardRef, OnInit, OnChanges, Output, EventEmitter} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';



export interface ChipAutocompleteItem {
  id: string,
  title: string
}
/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: 'chips-autocomplete.component.html',
  styleUrls: ['chips-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsAutocompleteComponent),
      multi: true
    }
  ],
})
export class ChipsAutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];

  disabled: boolean = false;
  onChange: any;
  onTouch: any;

  formCtrl: UntypedFormControl = new UntypedFormControl()

  selectedItems: ChipAutocompleteItem[] = [];
  allItems: ChipAutocompleteItem[] = [];
  filteredItems: ChipAutocompleteItem[] = this.allItems;

  loading: boolean = true;

  @Input() items: ChipAutocompleteItem[] = [];
  @Input() minimum: number;
  @Input() maximum: number;
  @Input() required: any ;
  @Input() appearance: string = "outline"
  @Input() label: string = "Type to search..."
  @Input() value: string[] = []

  @Output() change?: EventEmitter<string[]> = new EventEmitter();
  @Output() setSelection?: EventEmitter<ChipAutocompleteItem[]> = new EventEmitter();

  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor()
  {
    this.formCtrl.valueChanges.subscribe(value => {
      if (typeof value === "string")
        this.filterByName(value);
      else
        this.filterById(value)
      this.reopenAutocomplete()
    })
  }

  validateFn: Function;
  ngOnInit(): void {
    this.allItems = this.items
  }
  ngOnChanges(changes) {
    this.allItems = this.items

    this.realignSelection();
  }

  private unalignedValues : string[] = undefined;
  writeValue(obj: any): void {
    if (obj === "")
      return

    this.unalignedValues = obj
      
    if (this.items?.length > 0) {
      this.realignSelection()
    }
    this.setNewValue()
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
    this.setNewValue()
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  validate(c: UntypedFormControl) {

    return this.validateFn(c);
  }

  checkSeparatorCodes(event: any) {
    if (this.separatorKeysCodes.find((code) => {return code === event.keycode}) !== undefined) {
      this.add(event)
    }
  }


  realignSelection() {
    if (this.unalignedValues === undefined)
      return;

    this.selectedItems = this.allItems.filter((item) => this.unalignedValues.indexOf(item.id) > -1)
    this.unalignedValues = undefined;

    this.setNewValue()
  }

  add(event: MatChipInputEvent): void {
    // const input = event.input;
    // const value = event.value;

    // // Add our fruit
    // if (this.topFruit !== undefined) {
    //   this.Items.push(this.topFruit.trim());

    //   // Reset the input value
    //   if (input) {
    //     input.value = '';
    //   }
    // }

    // this.itemInput.closePanel();
    // this.formCtrl.setValue(null);
  }
  propagateChange = (_: any) => {};

  setNewValue() {
    this.propagateChange(this.selectedItems.map((item) => { return item.id }))

    this.change ?
      this.change.emit(this.selectedItems.map((item) => { return item.id })) : null

    this.setSelection ?
      this.setSelection.emit(this.selectedItems) : null
  }

  reset() {
    this.selectedItems = []
    this.filterById()
    this.setNewValue()
  }

  remove(item: ChipAutocompleteItem): void {
    
    const index = this.selectedItems.indexOf(item);

    if (index >= 0) {
      this.selectedItems.splice(index, 1);
    }

    this.filterById()

    this.setNewValue()
  }

  findItemByName(name: string) {
    return this.allItems.find((item) => item.title === name)
  }
  findItemById(id: string) {
    return this.allItems.find((item) => item.id === id)
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let item = this.findItemById(event.option.value)

    // if (this.selectedItems.length >= this.maximum) {
    //   this.snackbar.show({message: `Item '${item.title}' not added. You can add a maximum of  ${this.maximum} items.` })
    //   return
    // }

    this.selectedItems.push(item);

    this.itemInput.nativeElement.value = ""
    this.formCtrl.setValue(null);

    this.setNewValue()
  }

  filterById(value: string = undefined) {
    this.filteredItems = this._filterById(value);
  }

  filterByName(value: string = "") {
    this.filteredItems = this._filterByName(value);
  }

  private _filterById(value: string = undefined): ChipAutocompleteItem[] {
    const filterValue = value
    let filtered = this.allItems
      .filter(item => this.selectedItems.find(selected => selected.id === item.id) === undefined )
      .filter(item => item.id !== filterValue)

    // this.topFruit = filtered[0]

    return filtered;
  }
  private _filterByName(value: string): ChipAutocompleteItem[] {
    const filterValue = value ? value.toLowerCase() : "";
    let filtered = this.allItems
      .filter(item => this.selectedItems.find(selected => selected.id === item.id) === undefined )
      .filter(item => item.title.toLowerCase().indexOf(filterValue) >= 0)

    // this.topFruit = filtered[0]

    return filtered;
  }


  reopenAutocomplete() {
    // this.itemInput.openPanel()
  }

  getHint(): string {
    let hint = ""
    hint += this.minimum ? `minimal ${this.minimum} items` : "";
    hint += this.minimum & this.maximum ? ` and ` : "";
    hint += this.maximum ? `maximal ${this.maximum}` : "";

    return hint.charAt(0) ? hint.charAt(0).toUpperCase() + hint.slice(1) + " items" : '';

  }
}


export function minItems(minValue) {
  return function validateMinItems(c: UntypedFormControl) {
    let err = {
      rangeError: {
        given: c.value.length,
        min: minValue
      }
    };
    return (c.value.length < +minValue) ? err: null;
  }
}
export function maxItems(maxValue) {
  return function validateMinItems(c: UntypedFormControl) {
    let err = {
      rangeError: {
        given: c.value.length,
        max: maxValue
      }
    };
    return (c.value.length > +maxValue) ? err: null;
  }
}