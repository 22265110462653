

export const PHOTOGRAPHER_HOME: string = "/events";
export const SPORTS_LOVER_HOME: string = "/events";
// export const SPORTS_LOVER_HOME: string = "/nearby-photographers";


export const PHOTOGRAPHER_BLACKLIST: string[] = [
	"nearby-photographers",
	"purchases",
]

export const SPORTS_LOVER_BLACKLIST: string[] = [
	"event/new",
	"event/edit",

	"check-ins/new",

	"sales",

	"albums/me"
]