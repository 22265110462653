import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ToolbarScaffoldComponent } from './toolbar-scaffold.component';

@Injectable()
export class ToolbarScaffoldService {
  private toolbarScaffold: ToolbarScaffoldComponent;

	public setScaffold(scaffold: ToolbarScaffoldComponent) {
		this.toolbarScaffold = scaffold;
	}

  public setLoading(loading: boolean) {
		if (this.toolbarScaffold !== undefined)
    	this.toolbarScaffold.loading = loading
  }

  public getLoading() : boolean {
    return this.toolbarScaffold?.loading ? this.toolbarScaffold.loading : false
  }
}