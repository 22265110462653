import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatabaseService } from 'src/app/services/database.service';
import { FormService } from 'src/app/services/form.service';
import { User } from 'src/app/types/user.type';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {

  constructor(
  ) { }

  @Input('no-top-margin') noTopMargin: boolean = null;

  @Input() badge: any = undefined
  @Input() badgeColor: 'accent' | 'primary' = 'accent'
  @Input() textBadge: boolean = false

  ngOnInit(): void {
  }

}
