import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CheckIn, Location } from 'src/app/types/check-in.type';

@Component({
  selector: 'app-overlapping-dates-dialog',
  templateUrl: './overlapping-dates-dialog.component.html',
  styleUrls: ['./overlapping-dates-dialog.component.scss']
})
export class OverlappingDatesDialog implements OnInit {

  constructor(
    public _dialogRef: MatDialogRef<OverlappingDatesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: OverlappingDatesDialogData,

    private router: Router
  ) { }

  ngOnInit(): void {
  }


  onCheckInClicked(checkIn: CheckIn) {
    this._dialogRef.close(false)
    if (checkIn.event) {
      this.router.navigate(['/event/view', checkIn.event.id])
    } else {
      window.open(this.getMapsLink(checkIn.location), '_blank')
    }
  }


  getMapsLink(location: Location) {
    return `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`
  }
}


export interface OverlappingDatesDialogData {
  checkIns: CheckIn[]
}