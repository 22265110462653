import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { from, Observable, Subject, zip } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatabaseService } from 'src/app/services/database.service';
import { FormService } from 'src/app/services/form.service';
import { User, UserTypeItems, UserTypeItemUnits } from 'src/app/types/user.type';
import { ChipAutocompleteItem } from '../chips-autocomplete/chips-autocomplete.component';
import { ImageCropSheetComponent, ImageCropSheetData } from '../sheets/image-crop-sheet/image-crop-sheet.component';
import { ToolbarScaffoldService } from '../toolbar-scaffold/toolbar-scaffold.service';
import { countries } from '../../locale/countries'
import * as moment from 'moment';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {


  constructor(
    public fs: FormService,
    private auth: AuthenticationService,
    private router: Router,
    private snackbar: MatSnackBar,
    private database: DatabaseService,
    private sheet: MatBottomSheet,
    private scaffold: ToolbarScaffoldService
  ) {
    this._unsubscribeAll = new Subject()
  }

  private _unsubscribeAll: Subject<any>;

  @Input() editMode: 'register' | 'full' = 'full'

  user: User;

  @ViewChild('pictureInput') pictureInput: ElementRef;

  selectedImageSrc: string | ArrayBuffer;
  selectedImagePath: string;
  allChips: ChipAutocompleteItem[] = [];

  countries: { id:string, text: string}[] = countries
  filteredCountries: Observable<{ id:string, text: string }[]>;

  form = new UntypedFormGroup({
    "picture": new UntypedFormControl("", []),
    "username": new UntypedFormControl("", [Validators.required, Validators.maxLength(50)]),
    "about_me": new UntypedFormControl("", [Validators.maxLength(150)]),
    "birthdate": new UntypedFormControl("", []),
    "gender": new UntypedFormControl("", []),
    "country": new UntypedFormControl("", []),
    "user_type_items": new UntypedFormControl("", [])
  }, {validators: this.fs.passwordsMatchValidator})

  @Output() dirty: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() afterSaved: EventEmitter<void> = new EventEmitter<void>()

  ngOnInit(): void {
    // Get current user to edit

    this.database.getCurrentUserData().pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((user) => {
      this.user = user

      user.data.birthdate ?  this.user.data.birthdate = moment.unix(user.data?.birthdate?.seconds) : undefined

      if (this.user?.data?.picture) {
        this.selectedImageSrc = this.user.data.picture
      }

      // Get items for user type, so user can select items
      this.database.getUserTypeItems(user.data.user_type).subscribe((res) => {
        this.allChips = res

        this.form.patchValue({...user.data, picture: null})
      })
    })

    this.form.get("username").setAsyncValidators(
      (control: AbstractControl): Observable<ValidationErrors | null> => {
        return from(this.auth.isUsernameFree(control.value).then(res => {
          if (res)
            return null;
          else
            return {'usernameInUse': true};
        }))
      }
    )

    this.filteredCountries = this.form.get('country').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    // On dirty
    zip(
      this.form.statusChanges,
      this.form.valueChanges)
    .pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(
      () => {
        this.dirty?.emit(this.form.touched)
      }
    )
  }

  private _filter(text: string): { id:string, text: string }[] {
    const filterValue = text.toLowerCase();

    return this.countries.filter(option => option.text.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }

  profilePictureChanged(event: any) {
    let data: ImageCropSheetData = {
      aspectRatio: 1/1,
      image: event.target.files[0],
      isRound: true,
      imageCropped: this.profilePictureCropped.bind(this),
      cancelled: this.cancelCropping.bind(this)
    }
    this.sheet.open(ImageCropSheetComponent, { data: data })
    this.pictureInput.nativeElement.value = '' // reset to change image later
  }

  profilePictureCropped(event: ImageCroppedEvent) {
    this.selectedImageSrc = event.base64;
    this.form.get('picture').setValue(event.base64)
  }

  cancelCropping() {
    this.selectedImageSrc = '';
    this.pictureInput.nativeElement.value = ''
    this.form.get('picture').setValue(undefined)
  }

  saveEditedProfile() {
     if (!this.form.valid) {
      this.form.markAllAsTouched()
      this.form.markAsDirty()
      return;
    }

    this.scaffold.setLoading(true)

    this.database.updateCurrentUser(this.form.value).then((res) => {
      this.afterSaved?.emit()

      this.snackbar.open("Your profile has been updated successfully")

      this.scaffold.setLoading(false)
    })
  }


  get userTypeItems() {
    return UserTypeItems.get(this.user?.data?.user_type)
  }
  get userTypeItemUnit() {
    return UserTypeItemUnits.get(this.user?.data?.user_type)
  }
}
