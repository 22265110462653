import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pending-changes-dialog',
  templateUrl: './pending-changes-dialog.component.html',
  styleUrls: ['./pending-changes-dialog.component.scss']
})
export class PendingChangesDialogComponent implements OnInit {

  constructor(
    public _dialogRef: MatDialogRef<PendingChangesDialogComponent>,

  ) { }

  ngOnInit(): void {
  }

}
