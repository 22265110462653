import { Location } from '@angular/common';
import { ApplicationRef, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { interval, concat } from 'rxjs';
import { first } from 'rxjs/operators';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';
// import * as moment from 'moment-timezone';
import './locale/en-NL';
import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

  providers: [
    NotificationService
  ]
})
export class AppComponent {
  title = 'capture-me';

  updateDialogShowing: boolean = false;
  
  constructor(
    private applicationRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private dialogService: MatDialog,
    private router: Router,
    private notificationService: NotificationService
  ) {

    const appIsStable$ = this.applicationRef.isStable.pipe(first(isStable => isStable === true));
    const everyFiveMinutes$ = interval(5 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyFiveMinutes$);
    
    console.log('ServiceWorker update enabled: ', this.swUpdate.isEnabled);
    if (!this.swUpdate.isEnabled) {
      return
    }

    everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());
    if (this.swUpdate.isEnabled) {​​​​​​​​

      this.swUpdate.available.subscribe(() => {​​​​​​​​

        if (this.updateDialogShowing) {
          return;
        }

        this.updateDialogShowing = true;
        console.log('ServiceWorker update available: yes');
        
        this.dialogService.open(UpdateDialogComponent).beforeClosed().subscribe(response => {​​​​​​​​
          if (response === true) {​​​​​​​​
            this.swUpdate.activateUpdate().then(() => {​​​​​​​​
              window.location.reload();
            }​​​​​​​​);
          }​​​​​​​​
        }​​​​​​​​);

      }​​​​​​​​);

    }​​​​​​​​

  }

}
