import { MollieConfig } from "./mollie.type";
import { FsTimestamp } from "./payment.type";

export interface User extends FireUser {
	data: UserData
}

export class UserClass {
	user: User;

	constructor(user: User) {
		this.user = user
	}

	isSportsLover() : boolean {
		return this.user.data.user_type === UserType.SPORTS_LOVER
	}
	isPhotographer() : boolean {
		return this.user.data.user_type === UserType.PHOTOGRAPHER
	}

	getUsername() : string {
		return this.user?.data?.username ? this.user?.data?.username : this.user?.multiFactor.user.email
	}
	getUserTypeName() : string {
		return UserTypeNames.get(this.user?.data?.user_type)
	}
	getPicture() : string {
		return this.user?.data?.picture
	}


	get canSellPictures() {
		if (this.isPhotographer() == false) {
			return false;
		}

		return ((this.user.data.mollie_config?.authorized ?? false) && (this.user.data.mollie_config?.ready_for_sale ?? false)) && this.user.data.mollie_config.state == undefined
	}
}

export interface UserData { // User data from Firestore
	id?: string,
	username?: string,
	username_indexed?: string,
	user_type: number,
	finished_registration: boolean,
	terms_and_services_agreed: boolean,

	messaging_tokens?: {
		uuid: string, 
		token: string
	}[]
	
	// OPTIONAL AFTER REGISTRATION FINISH
	picture?: string,
	about_me?: string,
	user_type_items?: string[]

	birthdate?: any
	country?: string
	gender?: string

	is_deleted?: boolean

	// STATS
	stats?: {
		check_ins?: number,
		albums?: number
	}

	//PHOTOGRAPHER
	default_picture_price?: number;
	mollie_config?: MollieConfig;

}


export interface FireUser { // Firebase user
	multiFactor?: {
		enrolledFactors? : any,
		user: {
			displayName?: string | null;
			email?: string | null;
			phoneNumber?: string | null;
			photoURL?: string | null;
			providerId?: string;
			emailVerified?: boolean;
			/**
			 * The user's unique ID.
			 */
			uid?: string;
		}
	}
}


export const UserType = {
	SPORTS_LOVER: 1,
	PHOTOGRAPHER: 2
}

export const UserTypeNames : Map<typeof UserType | number, string> = new Map([
	[UserType.SPORTS_LOVER, "Sports lover"],
	[UserType.PHOTOGRAPHER, "Photographer"],
]);


export const UserTypeItems : Map<typeof UserType | number, string> = new Map([
	[UserType.SPORTS_LOVER, "sports"],
	[UserType.PHOTOGRAPHER, "standard equipment"],
]);
export const UserTypeItemUnits : Map<typeof UserType | number, string> = new Map([
	[UserType.SPORTS_LOVER, "sports"],
	[UserType.PHOTOGRAPHER, "equipment"],
]);