import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { DatabaseService } from '../services/database.service';

@Injectable({
  providedIn: 'root'
})
export class AdministratorGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthenticationService  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.hasAdministratorAccess(route, state);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAdministratorAccess(childRoute, state);
  }


  hasAdministratorAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      this.authService.hasAdministratorAccess().then(
        (access) => {
          if (access === undefined) {
            // User is undefined/not logged in, so redirect to login
            return resolve(this.router.createUrlTree['/login'])
          } else {

            // User is logged in
            if (access) {
              // Let's go mario
              return resolve(true)
            } else {
              // Act as if this page doesn't exist, redirect to home
              return resolve(this.router.createUrlTree(['/home']))
            }

          }
        }
      )
    })
  }

}
