import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdministratorGuard } from './guards/administrator.guard';
import { AuthStateGuard } from './guards/auth-state.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { ShowIntroductionGuard } from './guards/show-introduction.guard';


const routes: Routes = [
  {
    path      : '',
    pathMatch : 'full',
    redirectTo: 'login',
  },
  {
    path: 'legal',
    loadChildren: () => import('./modules/legal-contracts/legal-contracts.module').then(m => m.LegalContractsModule),
  },
  {
    path: 'capture-me-introduction',
    loadChildren: () => import('./modules/app-introduction/app-introduction.module').then(m => m.AppIntroductionModule),
  },
  {
    path: '',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivateChild: [ShowIntroductionGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/main-app/main-app.module').then(m => m.MainAppModule),
    canActivateChild: [ShowIntroductionGuard, AuthStateGuard]
  },
  {
    path      : '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false,
        scrollPositionRestoration: 'disabled'
      })
    ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
