<div class="list-item {{type}} {{compact ? 'compact' : ''}}" matRipple
	fxLayout="row nowrap" fxLayoutAlign="flex-start stretch"
	>


	<div class="item-prefix" fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="center center">
		<!-- Prefix -->
		<ng-content select="[item-prefix]"></ng-content>

	</div>


	<div class="item-image" fxFlex="0 0 auto">
		<!-- Image, icon -->
		<ng-content select="[item-image]"></ng-content>

	</div>


	<!-- HORIZONTAL container for content + suffix/actions + DIVIDER -->
	<div class="after-image" fxLayout="column nowrap" fxFlex="1 1 auto">

		<!-- VERTICAL Container for content + SUFFIX/ACTIONS -->
		<div class="item-content" fxLayout="row nowrap" fxLayoutAlign="center space-between">

			<!-- VERTICAL Container for CONTENT (titles and subtitle) -->
			<div class="item-content-col" fxFlex="1 1 auto" fxLayout="column nowrap" fxLayoutAlign="center stretch">

				<!-- HORIZONTAL Container for the titles -->
				<div class="title-row" fxLayout="row wrap-reverse" fxLayoutAlign="flex-end flex-start">
					
					<div class="item-title" fxFlex="1 1 auto">
						<ng-content select="[item-title]"></ng-content>
					</div>
		
					<div class="item-title-suffix" (click)="$event.stopPropagation()">
						<ng-content select="[item-title-suffix]"></ng-content>
					</div>
		
				</div>

				<!-- Container for subtitle -->
				<div class="subtitle-row">

					<ng-content select="[item-subtitle]"></ng-content>
					
				</div>
		
				<!-- Container for subtitle -->
				<div class="content-row">
		
					<ng-content select="[item-content]"></ng-content>
					
				</div>

				<div class="sub-content-row" fxLayout="row nowrap">

					<ng-content select="[item-sub-content]"></ng-content>

				</div>
				
			</div>

			<!-- HORIZONTAL Container for suffix/actions -->
			<div class="item-suffix" fxFlex="0 0 auto" fxLayout="row nowrap" (click)="$event.stopPropagation()">

				<ng-content select="[item-suffix]"></ng-content>

			</div>

		</div>

		<mat-divider class="divider"></mat-divider>

	</div>

	

	


</div>