import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrls: ['./notification-snack-bar.component.scss']
})
export class NotificationSnackBarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public message: any,
    public ref: MatSnackBarRef<NotificationSnackBarComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {
    // console.log(this.message)
  }


  get hasAction() {
    return this.message?.data?.action_url !== undefined
  }

  navigateToAction() {
    this.router.navigateByUrl(this.message?.data?.action_url?.replace(/^.*\/\/[^\/]+/, ''))
    this.ref.dismiss()
  }

}
