import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';


import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule} from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule, 
  APP_NAME as ANALYTICS_APP_NAME, 
  APP_VERSION as ANALYTICS_APP_VERSION, 
  DEBUG_MODE as ANALYTICS_DEBUG_MODE, 
  CONFIG as ANALYTICS_CONFIG,
  COLLECTION_ENABLED as ANALYTICS_ENABLED } from '@angular/fire/compat/analytics';

import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
// Material imports
import { AuthenticationService } from './services/authentication.service';
import { FormService } from './services/form.service';
import { DatabaseService } from './services/database.service';
import { AuthStateGuard } from './guards/auth-state.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { HttpClientModule } from '@angular/common/http';

import { ApplicationPipesModule } from './pipes/pipes.module';

import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { PendingChangesDialogComponent } from './components/dialogs/pending-changes-dialog/pending-changes-dialog.component';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';
import { NotificationService } from './services/notification.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationSnackBarComponent } from './components/notification-snack-bar/notification-snack-bar.component';
import { AdministratorGuard } from './guards/administrator.guard';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { UserTrackingService } from './services/user-tracking.service';
import { ScreenTrackingService } from './services/screen-tracking.service';
import { OverlappingDatesDialogModule } from './components/dialogs/overlapping-dates-dialog/overlapping-dates-dialog.module';
import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';
import { PaymentStatusChipComponent } from './components/payment-status-chip/payment-status-chip.component';


@NgModule({
  declarations: [
    AppComponent,
    UpdateDialogComponent,
    PendingChangesDialogComponent,

    NotificationSnackBarComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,

    NgxPicaModule,
    NgxScrollPositionRestorationModule.forRoot(),

    ApplicationPipesModule,

    MatMomentDateModule,
    MatDialogModule,
    MatButtonModule,

    MatSnackBarModule,

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    OverlappingDatesDialogModule,
  ],
  providers: [
    AuthenticationService,
    FormService,
    DatabaseService,
    NotificationService,

    AuthStateGuard,
    LoggedInGuard,
    AdministratorGuard,

    {provide: MAT_DATE_LOCALE, useValue: 'en-NL'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'L',
      },
      display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }},

		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS, 
			useValue: {
				hasBackdrop: true,
				maxWidth: 532,
				panelClass: 'app-sheet-maxwidth',
				backdropClass: 'app-darker-backdrop',
				closeOnNavigation: true
			}
		},
		{
			provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, 
			useValue: {
				hasBackdrop: true,
				maxWidth: 532,
				panelClass: 'app-sheet-maxwidth',
				backdropClass: 'app-darker-backdrop',
				closeOnNavigation: true
			}
		},


    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },



    UserTrackingService,
    ScreenTrackingService,
    { provide: ANALYTICS_APP_VERSION, useValue: `${environment.version.name}${environment.version.suffix}` },
    { provide: ANALYTICS_APP_NAME, useValue: environment.APP_NAME },
    { provide: ANALYTICS_DEBUG_MODE, useValue: !environment.production },
    { provide: ANALYTICS_ENABLED, useValue: environment.production },
    { provide: ANALYTICS_CONFIG, useValue: {
      allow_ad_personalization_signals: false,
    } }

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    iconRegistry: MatIconRegistry,
    // Analytics start services
    userTrackingService: UserTrackingService,
    screenTrackingService: ScreenTrackingService
    
    ) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined');

    // Analytics screen events
  }
}
