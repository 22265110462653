import { isPlatformServer } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { Inject, Injectable, NgZone, OnDestroy,  } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subscription } from 'rxjs';
import { UserTypeItems, UserTypeNames } from '../types/user.type';
import { DatabaseService } from './database.service';

@Injectable()
export class UserTrackingService implements OnDestroy {

  initialized: Promise<void>;
  private disposables: Array<Subscription> = [];

  // TODO a user properties injector
  constructor(
    analytics: AngularFireAnalytics,
    // tslint:disable-next-line:ban-types
    @Inject(PLATFORM_ID) platformId: Object,
    auth: AngularFireAuth,
    zone: NgZone,

		database: DatabaseService
  ) {

    if (!isPlatformServer(platformId)) {
      let resolveInitialized;
      this.initialized = zone.runOutsideAngular(() => new Promise(resolve => resolveInitialized = resolve));
      this.disposables = [
					database.getCurrentUserData().subscribe(user =>{
						if (user === undefined) {
							analytics.setUserId("")
							analytics.setUserProperties({
								username: '',
								user_type: ''
							})
						} else {
							analytics.setUserId(user.multiFactor.user.uid);
							analytics.setUserProperties({
									username: user.data.username,
									user_type: UserTypeNames.get(user.data.user_type)
								}
							)
						}
            resolveInitialized();
					}),
          // auth.credential.subscribe(credential => {
          //   if (credential) {
          //     const method = credential.user.isAnonymous ? 'anonymous' : credential.additionalUserInfo.providerId;
          //     if (credential.additionalUserInfo.isNewUser) {
          //       analytics.logEvent('sign_up', { method });
          //     }
          //     analytics.logEvent('login', { method });
          //   }
          // })
      ];
    } else {
      this.initialized = Promise.resolve();
    }

  }

  ngOnDestroy() {
    this.disposables.forEach(it => it.unsubscribe());
  }
}