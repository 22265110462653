import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FsTimestamp } from '../types/payment.type';

export type FirestoreDatePrintType = 'compact' | 'pretty';

/// Format is a strin based on the formatting language of Moment:
/// https://momentjs.com/docs/#/displaying/

@Pipe({name: 'fsDate'})
export class FirestoreDatePipe implements PipeTransform {
  transform(value: {start: FsTimestamp, end: FsTimestamp}, format: string): string {

    if (value === null)
      return ""

    let startDate = moment(value.start.seconds * 1000);
    let endDate =   moment(value.end.seconds *   1000);


    let res = startDate.format(format)

    if (value.start.seconds !== value.end.seconds) {
      res += " - " + endDate.format(format)
    }


    return res
  }
}

@Pipe({name: 'mDate'})
export class MomentDatePipe implements PipeTransform {
  transform(value: {start: Moment, end: Moment}, format: string): string {

    if (!value?.start || !value?.end)
      return undefined

    let res = value.start.format(format)
 
    if (value.start.utc() !== value.end.utc()) {
      res += " - " + value.end.format(format)
    }


    return res !== "" ? res : undefined
  }
}

@Pipe({name: 'sDate'})
export class SimpleDatePipe implements PipeTransform {
  transform(value: moment.Moment | Date, format: string): string {
    console.debug(value)
    return moment(value).utc().format(format)
  }
}

@Pipe({name: 'tDate'})
export class TimestampDatePipe implements PipeTransform {
  transform(value: FsTimestamp, format: string): string {
    const date = moment(value.seconds * 1000);
    return date.format(format)
  }
}