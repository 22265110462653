<p *ngIf="message?.notification?.title" class="title">{{message.notification.title}}</p>
<p *ngIf="message?.notification?.body"  class="body" >{{message.notification.body}}</p>

<div class="actions" *ngIf="hasAction">
	<button mat-button (click)="ref.dismiss()">
		Dismiss
	</button>
	<button mat-flat-button (click)="navigateToAction()">
		View
	</button>
</div>